/**
 * WakeLock.js - tries to keep the session running
 * see https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API
 */

import LoggingBase from "../base/loggingbase";

/**
 * internal WakeLock API wrapper class
 */
export default class WakeLock extends LoggingBase {

	/**
	 * constructs a new instance
	 */
	constructor() {
		super('ssn.WakeLock');
		this.wakeLock = null;
		if ( this._checkAPI() ) {
			this.log('WakeLock API available :-)! Going on...');
			this._initWakeLock();
		} else {
			this.log('WakeLock API not available in this browser :-(.');
		}
	}

	/**
	 * returns the singleton instance
	 * @returns {WakeLock} the singleton instance
	 */
	static getInstance() {
		return instance;
	}

	/**
	 * destructor method
	 */
	destroy() {
		if ( this.handleVisibilityChange ) {
			document.removeEventListener('visibilitychange', this.handleVisibilityChange);
		}
		if ( this.wakeLock ) {
			this.wakeLock.release();
		}
		delete this.wakeLock;
		delete this.requestWakeLock;
		delete this.handleVisibilityChange;
	}

	/**
	 * requests a wake lock, if possible
	 */
	setWakeLock() {
		if ( this.requestWakeLock ) {
			this.requestWakeLock();
		} else {
			this.log('WakeLock API not available.');
		}
	}

	/**
	 * checks whether the browser supports the WakeLock API
	 * @returns {Boolean} true if the browser supports the WakeKock API; false otherwise
	 */
	_checkAPI() {
		return ('wakeLock' in navigator);
	}

	_reRequest() {
		if ( this.wakeLock && (document.visibilityState === 'visible') ) {
			this.log('Re-requesting a wake lock after document activation...');
			this.requestWakeLock();
		}
	}

	/**
	 * initializes the 
	 */
	_initWakeLock() {
		const self = this;
		const rqwl = async () => {
			self.log('Requesting wait lock.');
			try {
				const wl = await navigator.wakeLock.request('screen');
				self.wakeLock = wl;
				self.log('Successfully requested a wake lock.');
			} catch ( err ) {
				self.warn(`${err.name}, ${err.message}`);
			}
		};
		const hvc = () => {
			self._reRequest();
		};
		this.requestWakeLock = rqwl;
		this.handleVisibilityChange = hvc;
		document.addEventListener('visibilitychange', this.handleVisibilityChange);
	}
};

const instance = new WakeLock();
