import PSA from '../../psa';
import HtmHelper from '../../utils/HtmHelper';
import Validator from '../../utils/Validator';
import ExternalEventsTransferManager from '../../utils/ExternalEventsTransferManager';
import EventListenerManager from '../../utils/EventListenerManager';
import ObjReg from '../../utils/ObjReg';
import JsSize from '../../utils/JsSize';
import XtwHeadContextMenuExtension from './impl/contextmenu/XtwHeadContextMenuExtension';
import XtwMgr from './util/XtwMgr';
import XtwTbl from './XtwTbl';
import XtwBody from './XtwBody';
import XtwCol from './parts/XtwCol';
import ItmMgr from '../../gui/ItmMgr';
import XtwUtils from './util/XtwUtils';
import CellCtt from './model/CellCtt';
import Utils from '../../utils/Utils';
import UIRefresh from '../../utils/UIRefresh';
import ListenerItem from '../../utils/ListenerItem';
import DomEventHelper from '../../utils/DomEventHelper';

const OWN_BACKGROUND_COLOR = "--rtp-own-background-color";
const OWN_TEXT_COLOR = "--rtp-own-text-color";

const RDR_COLUMNFIT = 'columnFit';
const RDR_COLUMNUPD = 'columnUpdate';
const RDR_REBUILDHDR = 'rebuildHeader';

/**
 * class XtwHead - the header of an eXtended Table Widget (XTW)
 */
export default class XtwHead extends ListenerItem {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor( properties ) {
		super('widgets.xtw.XtwHead');
		Utils.bindAll( this, [ "layout", "onReady", "onInitRender" ] );
		this.ready = false;
		this.xtwBody = null;
		this.element = null;
		this.clrTxt = null;
		this.hscPos = 0;
		this.scbWdt = 0;
		this.sortColumn = -1;
		this.sortDirection = false;
		this.fieldMenu = null;
		this.rcoArgs = null;
		this.size = new JsSize( -1, -1 );
		this.renderRqu = PSA.getInst().getRequestHolder();
		this._hrbPending = false;
		this._rfrCallback = null;
		// the main column container
		this.columns = new ObjReg();
		new XtwHeadContextMenuExtension( this );
		this.rtpColumns = null;
		// an "ordered" column container
		const oc = {};
		oc.fix = [];
		oc.dyn = [];
		this.ordCol = oc;
		// get the RAP parent element
		const idw = '' + properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject( idw );
		// create our "own" DOM element
		this.element = document.createElement( 'div' );
		this.element.classList.add( "xtwhead" );
		if ( Validator.is( this.element.dataset, "DOMStringMap" ) ) {
			const className = Validator.getClassName( this );
			if ( Validator.isString( className ) ) {
				this.element.dataset.class = className;
			}
		}
		// create scroll container
		const sc = document.createElement( 'div' );
		sc.dataset.class = "scroll container";
		// create "fixed" and "dynamic" column containers
		const cf = document.createElement( 'div' );
		cf.dataset.class = "fixed container";
		const cd = document.createElement( 'div' );
		cd.dataset.class = "dynamic container";
		this.element.appendChild( cf );
		sc.appendChild( cd );
		this.element.appendChild( sc );
		this.scrCnt = sc;
		this.ccnFix = cf;
		this.ccnDyn = cd;
		this.wdtFix = 0;
		this.wdtDyn = 0;
		this.visWdt = 0;
		// add the main DOM element to the RAP parent
		this.parent.append( this.element );
		// we need the resize listener
		this.parent.addListener( "Resize", this.layout );
		// activate "render" event
		rap.on( "render", this.onInitRender );
		// get custom widget data - we need the ID of our parent eXtended Table Widget
		this.xtdTbl = null;
		const cwd = this.parent.getData( "pisasales.CSTPRP.CWD" ) || {};
		if ( Validator.isString( cwd.searchInColumnsMenuOptionTitle ) ) {
			Object.defineProperty( this, "searchInColumnsMenuOptionTitle", {
				value: cwd.searchInColumnsMenuOptionTitle,
				writable: false,
				configurable: false
			} );
		}
		// ID string
		this.wdgIdStr = cwd.idstr || '';
		if ( this.element.dataset ) {
			this.element.dataset.idstr = this.wdgIdStr;
		}
		const idp = cwd.idp || '';
		if ( idp ) {
			const xtw = XtwMgr.getInst().getXtdTbl( idp );
			if ( xtw instanceof XtwTbl ) {
				xtw.setHeadWdg( this );
				this.xtdTbl = xtw;
			}
		}
		this.selAllTtl = cwd.selallttl || '';
		this._colSumEnabled = !!cwd.colSumEnabled;
		// remaining initialization
		this._init();
	}

	/**
	 * called by the framework to destroy the widget
	 * @override
	 */
	destroy() {
		this.log('Passing away.');
		super.destroy();
	}
	
	/**
	 * @override
	 */
	doDestroy() {
		this.removeAllListeners();
		this.ready = false;
		this.ordCol.fix = [];
		this.ordCol.dyn = [];
		if ( this.rtpColumns ) {
			this.rtpColumns.clear();
			delete this.rtpColumns;
		}
		if ( this._menuHandler ) {
			this._menuHandler.destroy();
			delete this._menuHandler;
		}
		this._dropFieldMenu();
		delete this.fieldMenu;
		delete this.ordCol;
		this.columns.destroy();
		delete this.xtwBody;
		delete this.xtdTbl;
		delete this.columns;
		delete this.ready;
		delete this.size;
		delete this.clrTxt;
		delete this.renderRqu;
		if ( this.element && this.element.parentNode ) {
			this.element.parentNode.removeChild( this.element );
		}
		delete this.ccnDyn;
		delete this.ccnFix;
		delete this.scrCnt;
		delete this.element;
		super.doDestroy();
	}

	/**
	 * @return {Boolean} whether or not the current template is a "row template";
	 * "true" if the current template is a "row template", "false" otherwise
	 */
	get isRowTpl() {
		if ( !(this.xtwBody instanceof XtwBody) ) {
			return false;
		}
		return this.xtwBody.isRowTpl;
	}

	/**
	 * @return {Boolean} whether or not the "row template" view/display is supported
	 */
	get hasRowTpl() {
		if ( !(this.xtdTbl instanceof XtwTbl) ) {
			return false;
		}
		return this.xtdTbl.hasRowTpl === true;
	}

	/**
	 * @returns {Boolean} true if the column sum feature is enabled; false otherwise
	 */
	get isColSumEnabled() {
		return this._colSumEnabled;
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
	}

	/**
	 * called by the framework in rendering phase - this is the initial "render" listener
	 */
	onInitRender() {
		if ( this.parent ) {
			rap.off( "render", this.onInitRender ); // just once!
			this.onReady();
			this.layout();
			this._attachEventHandlers();
			this._rebuildHeader();
		}
	}

	/**
	 * handles the "mouseleave" event when it happens on the table widget element
	 * @param {MouseEvent} evt the "mouseleave" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onTblMouseLeave( evt ) {
		return this.onMouseLeave( evt );
	}

	/**
	 * handles the "mousemove" event when it happens on the table widget element
	 * @param {MouseEvent} evt the "mousemove" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onTblMouseMove( evt ) {
		// TODO
	}

	/**
	 * handles the "mouseup" event when it happens on the table widget element
	 * @param {MouseEvent} evt the "mouseup" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onTblMouseUp( evt ) {
		if ( this.movedColumn instanceof XtwCol ) {
			const result = {};
			const column = this._getColumnFromEvent(evt, result);
			if ( (column instanceof XtwCol) || (result.hit === true) ) {
				this.columnTitleMouseUp(evt, column);
			}
		}
		this.voidMousedownProperties();
		return this.voidMovingProperties( true );
	}

	/**
	 * handles the "wheel" event when it happens on the table widget element
	 * @param {Event} evt the "wheel" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onTblMouseWheel( evt ) {
		return this.voidMovingProperties( true );
	}

	/**
	 * initiates a column drag operation
	 * @param {MouseEvent} evt the mouse event
	 * @param {XtwCol} col the affected column
	 * @param {HTMLElement} elm the "hot" element
	 * @param {Boolean} full "full column" drag
	 */
	onColumnDrag( evt, col, elm, full ) {
		this.mouseDownEvent = evt;
		if ( this.xtdTbl instanceof XtwTbl ) {
			this.voidMousedownProperties();
			this.xtdTbl.onColumnDrag( evt, col, elm, full );
		}
	}

	/**
	 * called if the user clicks the "select" column header element
	 * @param {MouseEvent} evt the "click" mouse event
	 */
	onSelClick( evt ) {
		if ( !( evt instanceof MouseEvent ) ) {
			return;
		}
		const xtb = this.xtwBody;
		if ( xtb instanceof XtwBody ) {
			xtb.toggleSelectAll();
		}
		return true;
	}

	/**
	 * called after the user has changed a column with by dragging the column border
	 * @param {XtwCol} col the affected column
	 * @param {Number} width new column width
	 * @param {MouseEvent} evt the mouse up event
	 */
	onColumnWidth( col, width, evt ) {
		if ( Validator.isString( width ) ) {
			width = Number( width );
		}
		if ( !Validator.isValidNumber( width ) ) {
			return;
		}
		width = Math.round( width ); // only integer widths
		if ( (this.xtdTbl instanceof XtwTbl) && this.xtdTbl.resizeColumnOnAutoFit(col, width) ) {
			// the table widget handled the column resize due to the fact that the auto-fit mode is active; no further handling needed
			return;
		}
		const par = {};
		par.idc = col.id;
		par.width = width;
		this._nfySrv( 'columnWidth', par );
	}

	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		if ( !this.ready ) {
			return;
		}
		const area = this.parent.getClientArea();
		const wdt = area[ 2 ] || 0;
		const hgt = area[ 3 ] || 0;
		this.element.style.left = '0';
		this.element.style.top = '0';
		this.visWdt = wdt;
		this.element.style.width = wdt + 'px';
		// this.element.style.height = hgt + 'px';
	}

	/**
	 * gets & returns the "rendered" status of this item, which is true when this
	 * item has a valid HTML element and false otherwise
	 * @return {Boolean} true if element is present & valid, false otherwise
	 */
	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	/**
	 * @returns {XtwCol[]} an array of all columns
	 */
	get columnsArray() {
		if ( !Validator.is( this.columns, "ObjReg" ) ) {
			return void 0;
		}
		const columnsIterator = this.columns.getIterable();
		return Array.from(columnsIterator);
	}

	/**
	 * @returns {XtwCol[]} an ordered array of all data columns
	 */
	get orderedColumns() {
		if ( !this.alive ) {
			return [];
		}
		const fixed = Array.from( this.ordCol.fix ).filter( column => ( column instanceof XtwCol ) && !column.select );
		const dynamic = Array.from( this.ordCol.dyn ).filter( column => ( column instanceof XtwCol ) && !column.select );
		return fixed.concat( dynamic );

	}

	/**
	 * @returns {XtwCol[]} an ordered array of all currently visible data columns
	 */
	get currentlyVisibleColumns() {
		const orderedColumns = this.orderedColumns;
		return orderedColumns.filter( column => (column instanceof XtwCol) && column.visible );
	}

	/**
	 * @returns {XtwCol | null} the first visible data column
	 */
	get firstVisibleColumn() {
		const currentlyVisibleColumns = this.currentlyVisibleColumns;
		return currentlyVisibleColumns.length > 0 ? currentlyVisibleColumns[ 0 ] : null;
	}

	/**
	 * @returns {XtwCol | null} the last visible data column
	 */
	get lastVisibleColumn() {
		const currentlyVisibleColumns = this.currentlyVisibleColumns;
		return currentlyVisibleColumns.length > 0 ? currentlyVisibleColumns[ currentlyVisibleColumns.length - 1 ] : null;
	}

	get firstVisibleColumnId() {
		const firstVisibleColumn = this.firstVisibleColumn;
		return Validator.isObject( firstVisibleColumn ) ? firstVisibleColumn.id : void 0;
	}

	get lastVisibleColumnId() {
		const lastVisibleColumn = this.lastVisibleColumn;
		return Validator.isObject( lastVisibleColumn ) ? lastVisibleColumn.id : void 0;
	}

	/**
	 * adds listeners to this item's element that are meant to assist the
	 * process of "column movement"
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	addListeners() {
		return this.addListener("mousemove", "onMouseMove");
	}

	/**
	 * gets & returns the HTML parent element of the table widget
	 * @see XtwTbl.js~XtwTbl~parElm
	 * @return {HTMLElement} the table widget's parent element
	 */
	get tableParentElement() {
		return (this.xtdTbl instanceof XtwTbl) ? (this.xtdTbl.parElm instanceof HTMLElement ? this.xtdTbl.parElm : null) :  null;
	}

	/**
	 * gets & returns the HTML parent element of the HTML element corresponding
	 * to this item
	 * @return {HTMLElement} the parent element of this item's element
	 */
	get parentElement() {
		return !( this.element instanceof HTMLElement ) ? null : (!( this.element.parentElement instanceof HTMLElement ) ? null : this.element.parentElement);
	}

	/**
	 * gets the coordinates (x and y) of this position relative to the table
	 * widget element, based on the coordinates of the event
	 * @param {Event} evt the event
	 * @return {Object} the position object, with to properties: x and y
	 * @see XtwTbl.js~XtwTbl~_getEffPos
	 */
	getEffectivePosition( evt ) {
		return (this.xtdTbl instanceof XtwTbl) ? this.xtdTbl._getEffPos( evt ) : null;
	}

	/**
	 * sets the current effective scrollbar width
	 * @param {Number} scb current effective scrollbar width
	 */
	setScb( scb ) {
		this.scbWdt = scb || 0;
	}

	/**
	 * called if the user scrolls horizontally
	 * @param {Number} pos current scrolling position
	 */
	onHScroll( pos ) {
		const vwd = this.visWdt - this.scbWdt;
		if ( ( vwd > 0 ) && this.wdtDyn && ( this.hscPos !== pos ) ) {
			let eff_pos = pos;
			const full_wdt = this.wdtFix + this.wdtDyn;
			if ( ( pos !== 0 ) && ( ( vwd + pos ) > full_wdt ) ) {
				eff_pos -= vwd + pos - full_wdt;
			}
			if ( this.hscPos !== eff_pos ) {
				this.hscPos = eff_pos;
				if ( this.ccnDyn ) {
					this.ccnDyn.style.left = '' + ( -this.hscPos ) + 'px';
				}
			}
		}
	}

	ensureHorizontalScrollPositionsAreSynced() {
		if ( Validator.isObject( this.xtwBody ) && Validator.isFunction( this.xtwBody.forceSyncHorizontalScrolling ) ) {
			return this.xtwBody.forceSyncHorizontalScrolling();
		}
		return false;
	}

	/**
	 * ensures that a column is visible
	 * @param {XtwCol} column the column that should be3 visible
	 * @param {Boolean} force force flag
	 * @returns {Boolean} true if successful; false otherwise
	 */
	scrollToColumn( column, force = false ) {
		if ( !(column instanceof XtwCol) || !column.isRendered || column.fix || !(this.xtdTbl instanceof XtwTbl) ) {
			return false;
		}
		const scbHorz = this.scbHorz;
		if ( !Validator.isObject( scbHorz ) ) {
			return false;
		}
		const bodyRect = this.bodyClientRect;
		if ( !( bodyRect instanceof DOMRect ) ) {
			return false;
		}
		const columnRect = column.element.getBoundingClientRect();
		if ( !( columnRect instanceof DOMRect ) ) {
			return false;
		}
		const fixedContainerRect = this.fixedContainerRect;
		if ( !( fixedContainerRect instanceof DOMRect ) ) {
			return false;
		}
		const bodyEnd = bodyRect.x + bodyRect.width;
		const columnEnd = columnRect.x + columnRect.width;
		const fixedContainerEnd = fixedContainerRect.x + fixedContainerRect.width;
		let hsp = scbHorz._selection;
		if ( columnEnd > bodyEnd ) {
			// scroll to the left
			hsp += columnEnd - bodyEnd;
		} else if ( columnRect.x < bodyRect.x ) {
			// scroll to the right
			hsp += columnRect.x - bodyRect.x - fixedContainerRect.width;
		} else if ( fixedContainerEnd > columnRect.x ) {
			// scroll to the right
			hsp += columnRect.x - fixedContainerEnd;
		}
		if ( force || (hsp !== scbHorz._selection) ) {
			if ( force ) {
				// force the table body to update the scrolling position
				this.xtwBody.resetHScroll();
			}
			scbHorz._selection = hsp + 1;
			scbHorz.setSelection( hsp );
		}
		return true;
	}

	get scbHorz() {
		if ( !(this.xtdTbl instanceof XtwTbl) ) {
			return null;
		}
		return this.xtdTbl.wdgSlh;
	}

	onHorizontalScrollbarHidden() {
		this.onHScroll( 0 );
		return true;
	}

	onHorizontalScrollbarShown() {
		const widgetHorizontalSelection = this.scbHorz;
		if ( !Validator.isObject( widgetHorizontalSelection ) || !Validator.isValidNumber( widgetHorizontalSelection._selection ) ) {
			return false;
		}
		this.onHScroll( widgetHorizontalSelection._selection );
		return true;
	}

	onVerticalScrollbarHidden() {
		return true;
	}

	onVerticalScrollbarShown() {
		return true;
	}

	/**
	 * removes the temporary properties and flags that were set to assist during
	 * the process of "column movement"; also removes the column title preview
	 * HTML element from the DOM
	 * @param returnValue what this method should return
	 * @return the initial "returnValue" parameter
	 */
	voidMovingProperties( returnValue = void 0 ) {
		this.movedColumn = void 0;
		delete this.movedColumn;
		if ( this.movedColumnPreviewElement instanceof HTMLElement ) {
			[ "mousemove", "mouseup" ].forEach( eventName => {
				EventListenerManager.removeListener( this, eventName,
					this.movedColumnPreviewElement, "ColumnMovementPreviewElement" );
			} );
			const movedColumnPreviewElement = this.movedColumnPreviewElement;
			movedColumnPreviewElement.remove();
		}
		this.movedColumnPreviewElement = void 0;
		delete this.movedColumnPreviewElement;
		return returnValue;
	}

	voidMousedownProperties( returnValue = void 0 ) {
		this.mouseDownEvent = void 0;
		delete this.mouseDownEvent;
		return returnValue;
	}

	/**
	 * handles the "mouseleave" event when it happens on this item's element
	 * @param {MouseEvent} evt the "mouseleave" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onMouseLeave( evt ) {
		this.voidMousedownProperties();
		return this.voidMovingProperties( true );
	}

	/**
	 * handles the "mousemove" event when it happens on this item's element
	 * @param {MouseEvent} evt the "mousemove" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onMouseMove( evt ) {
		const previewElement = this.getMovedColumnPreviewElement();
		if ( !( previewElement instanceof HTMLElement ) ) {
			return this.voidMovingProperties( false );
		}
		const effectivePosition = this.getEffectivePosition( evt );
		if ( !Validator.isObject( effectivePosition ) ) {
			return this.voidMovingProperties( false );
		}
		previewElement.style.left = `${ effectivePosition.x + 5 }px`;
		evt.stopPropagation();
		evt.preventDefault();
		return true;
	}

	/**
	 * gets and returns the temporary "preview" HTML element that is attached
	 * to the table head element during the process of column movement and that
	 * replicates/illustrates the title cell element from the column that is
	 * supposed to be moved; if the preview element already exists (is already
	 * present), this method simply returns it, otherwise the element is newly
	 * rendered
	 * @return {HTMLDivElement} the temporary element for the column moving
	 * preview
	 */
	getMovedColumnPreviewElement() {
		if ( this.movedColumnPreviewElement instanceof HTMLElement ) {
			return this.movedColumnPreviewElement;
		}
		if ( !(this.movedColumn instanceof XtwCol) || !( this.movedColumn.element instanceof HTMLElement ) ) {
			return this.voidMovingProperties();
		}
		const headParentElement = this.parentElement;
		if ( !Validator.isObject( headParentElement ) ) {
			return this.voidMovingProperties();
		}
		const copyOfMovedColumn = this.movedColumn.element.cloneNode( true );
		copyOfMovedColumn.classList.add( "temporary" );
		copyOfMovedColumn.style.zIndex = "99";
		copyOfMovedColumn.style.position = "absolute";
		copyOfMovedColumn.style.backgroundColor = "gray"; // TODO change
		copyOfMovedColumn.style.color = "white"; // TODO change
		this.addPrefixListener("mousemove", "onMouseMove", "ColumnMovementPreviewElement", copyOfMovedColumn);
		this.addPrefixListener("mouseup", "columnMovementPreviewElementMouseUp", "ColumnMovementPreviewElement", copyOfMovedColumn);
		headParentElement.insertBefore( copyOfMovedColumn, headParentElement.firstChild );
		this.movedColumnPreviewElement = copyOfMovedColumn;
		return this.movedColumnPreviewElement;
	}

	/**
	 * handles the "mousedown" event when it happens on the "main span" of a
	 * column that should potentially be moved
	 * @param {MouseEvent} evt the "mousedown" event
	 * @param {XtwCol} movedColumn the column that should be moved (on whose
	 * "main span" the event happened)
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	spanMouseDown( evt, movedColumn ) {
		if ( !( evt instanceof MouseEvent ) || evt.button !== 0 ) {
			return false;
		}
		this.mouseDownEvent = evt;
		if ( !(movedColumn instanceof XtwCol) ) {
			return this.voidMovingProperties( false );
		}
		this.movedColumn = movedColumn;
		evt.stopPropagation();
		evt.preventDefault();
		return true;
	}

	/**
	 * retrieves the column at the coordinates of an event
	 * @param {MouseEvent} evt the mouse event
	 * @param {Object} result a result object getting a "hit" info
	 * @returns {XtwCol|null} the column or null
	 */
	_getColumnFromEvent(evt, result) {
		const me = this.movedColumnPreviewElement;
		if ( me instanceof HTMLElement ) {
			me.style.display = 'none';
			// me.remove();
		}
		result.hit = false;
		let ce = null;
		let te = window.document.elementFromPoint(evt.clientX, evt.clientY);
		while ( (ce === null) && (te instanceof HTMLElement) && (te !== this.element) ) {
			if ( Validator.isNumber(te.__cid) ) {
				ce = te;
				te = null;
			} else {
				te = te.parentElement;
			}
		}
		if ( (te instanceof HTMLElement) && (te === this.element) ) {
			result.hit = true;
		}
		if ( ce instanceof HTMLElement ) {
			result.hit = true;
			return this.getColumn(ce.__cid);
		}
		return null;
	}

	/**
	 * handles the "mouseup" event when it happens on the temporary HTML
	 * element for the column moving preview
	 * @param {MouseEvent} evt the "mouseup" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	columnMovementPreviewElementMouseUp( evt ) {
		// find the column
		const result = {};
		const column = this._getColumnFromEvent(evt, result);
		if ( (column instanceof XtwCol) || (result.hit === true) ) {
			this.columnTitleMouseUp( evt, column );
		} else {
			DomEventHelper.stopEvent(evt);
			this.voidMovingProperties();
			this.voidMousedownProperties();
		}
		return true;
	}

	/**
	 * called if the user has clicked on the secondary ("language") icon
	 * @param {XtwCol} column the column
	 */
	onSecondIconClicked( column ) {
		this._nfyTblBody();
		PSA.getInst().cliCbkWdg.setBscRqu();
		this._nfySrv( 'secondIconClicked', { idc: column.id } );
	}

	/**
	 * determines the effective target column for a column drag operation
	 * @param {MouseEvent} evt the "mouseup" event
	 * @param {XtwCol} targetColumn the column on whose title the event happened
	 * @returns {XtwCol | null} the effective target column
	 */
	_getEffectiveTargetColumn(evt, targetColumn) {
		if ( !(targetColumn instanceof XtwCol) ) {
			return null;
		}
		const mde = this.mouseDownEvent;
		if ( (evt instanceof MouseEvent) && (mde instanceof MouseEvent) ) {
			if ( evt.clientX > mde.clientX ) {
				// dragging to the right
				const visibleColumns = this.currentlyVisibleColumns;
				const mix = visibleColumns.indexOf(this.movedColumn);
				const tix = visibleColumns.indexOf(targetColumn);
				if ( (mix !== -1) && (tix !== -1) && (tix < (visibleColumns.length -1)) ) {
					if ( Math.abs(tix - mix) < 2 ) {
						return visibleColumns[tix+1];
					}
				}
			}
		}
		return targetColumn;
	}

	/**
	 * handles the "mouseup" event when it happens on a XtwCol item's HTML
	 * element, which corresponds to the title of a column
	 * @param {MouseEvent} evt the "mouseup" event
	 * @param {XtwCol} targetColumn the column on whose title the event happened
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	columnTitleMouseUp( evt, targetColumn ) {
		const xtb = this.xtdTbl;
		if ( (xtb instanceof XtwTbl) && xtb.isColumnDragging() ) {
			// not for us!
			this.voidMousedownProperties();
			return this.voidMovingProperties(false);
		}
		DomEventHelper.stopEvent(evt);
		if ( (this.movedColumn === targetColumn) || this.mousedownAndMouseupHappenedOnSameSpot( evt ) ) {
			const titleColumnSorted = this.sortTitleColumn( evt, targetColumn );
			this.voidMousedownProperties();
			return this.voidMovingProperties( false ) && titleColumnSorted;
		}
		let eff_target = targetColumn;
		if ( (eff_target instanceof XtwCol) && eff_target.select ) {
			// use first available column
			eff_target = this.firstVisibleColumn;
			if ( !(eff_target instanceof XtwCol) ) {
				this.voidMousedownProperties();
				return this.voidMovingProperties(true);
			}
		}
		eff_target = this._getEffectiveTargetColumn(evt, eff_target);
		let moved = false;
		if ( eff_target instanceof XtwCol ) {
			moved = this.moveOneColumnBeforeTheOther(this.movedColumn, eff_target);
		} else {
			// move "movedColumn" to the end
			moved = this.moveColumnToEnd(this.movedColumn);
		}
		this.voidMousedownProperties();
		if ( !moved ) {
			const titleColumnSorted = this.sortTitleColumn( evt, eff_target );
			return this.voidMovingProperties( false ) && titleColumnSorted;
		}
		// notify the web server about the new column order
		this.notifyColumnOrder();
		// ok, clean-up
		return this.voidMovingProperties( true );
	}

	isValidRenderedMovableColumn( column ) {
		return (column instanceof XtwCol) && (column.element instanceof HTMLElement) && !column.select;
	}

	/**
	 * retrieves a column by ID
	 * @param {Number} columnId column ID
	 * @returns {XtwCol|null} the column or null if not found
	 */
	getColumn( columnId ) {
		if ( !this.alive ) {
			return null;
		}
		if ( !Validator.isPositiveInteger( columnId ) ) {
			return null;
		}
		return this.columns.getObj(columnId);
	}

	/**
	 * moves a column before another column
	 * @param {XtwCol} movedColumn the column to be moved
	 * @param {XtwCol} targetColumn the target column
	 * @returns {Boolean} true if successful; false otherwise
	 */
	moveOneColumnBeforeTheOther( movedColumn, targetColumn ) {
		if ( [ movedColumn, targetColumn ].some( column => (
				!(column instanceof XtwCol) ||
				!Validator.isPositiveInteger( column.id ) ||
				!( column.element instanceof HTMLElement ) ) ) ||
			movedColumn === targetColumn ) {
			return false;
		}
		if ( targetColumn.fix && !movedColumn.fix && (this.ordCol.dyn.length < 2) ) {
			// reject! the last not fixed column cannot be moved into the fixed part
			return false;
		}
		// step 1:
		if ( !this.moveFirstColumnToSecond( movedColumn, targetColumn, true ) ) {
			return false;
		}
		// step 2:
		const body = this.xtwBody;
		if ( (body instanceof XtwBody) && body.alive ) {
			body.moveFirstColumnToSecond( movedColumn, targetColumn, true );
		}
		// step 3:
		this.updateColumnOrder( movedColumn, targetColumn, true );
		// step 4:
		movedColumn.fix = targetColumn.fix;
		movedColumn.element.__fix = targetColumn.fix;
		return true;
	}

	/**
	 * moves a column to the end of the visible columns
	 * @param {XtwCol} column the column to be moved
	 */
	moveColumnToEnd(column) {
		if ( column instanceof XtwCol ) {
			const last = this.lastVisibleColumn;
			if ( last instanceof XtwCol ) {
				if ( !last.fix ) {
					if ( !this.moveFirstColumnToSecond(column, last, false) ) {
						return false;
					}
					const body = this.xtwBody;
					if ( (body instanceof XtwBody) && body.alive ) {
						body.moveFirstColumnToSecond(column, last, false);
					}
					this.updateColumnOrder(column, last, false);
					column.fix = last.fix;
					column.element.__fix = last.fix;
					return true;
				} else {
					// TODO - fixed?!
				}
			}
		}
		return false;
	}

	/**
	 * moves the title cell (precisely its HTML element) from the first column to a position exactly before or after the
	 * corresponding (second) title cell in the second column; also changes/adjusts the fixed and dynamic cell containers'
	 * widths in the case/scenario when the title cell element is moved from a fixed container to a dynamic one or vice versa
	 * @param {XtwCol} firstColumn the first column (the one whose title cell should be moved and should change its place)
	 * @param {XtwCol} secondColumn the second column (the one whose title cell should keep its place)
	 * @param {Boolean} before indicates whether the moved column should be inserted before the second column
	 * @return {Boolean} true if the movement was successful, false otherwise
	 */
	moveFirstColumnToSecond( firstColumn, secondColumn, before = true) {
		if ( [ firstColumn, secondColumn ].some( column => (!(column instanceof XtwCol) || !(column.element instanceof HTMLElement)) ) ||
				(firstColumn === secondColumn) ||
				!(secondColumn.element.parentElement instanceof HTMLElement) ) {
			return false;
		}
		let same_containers = true;
		if ( firstColumn.fix !== secondColumn.fix ) {
			// later, we must change the containers!
			same_containers = false;
		}
		// connect the moved column to its new DOM parent
		if ( before ) {
			secondColumn.element.parentElement.insertBefore(firstColumn.element, secondColumn.element);
		} else {
			const sibling = secondColumn.element.nextElementSibling;
			if ( sibling instanceof HTMLElement ) {
				secondColumn.element.parentElement.insertBefore(firstColumn.element, sibling);
			} else {
				secondColumn.element.parentElement.appendChild(firstColumn.element);
			}
		}
		if ( same_containers ) {
			// the containers were not changed
			return true;
		}
		const fixedWidthDifference = firstColumn.width * ( firstColumn.fix ? -1 : 1 );
		const dynamicWidthDifference = -1 * fixedWidthDifference;
		return this.adjustFixedAndDynamicWidths( fixedWidthDifference, dynamicWidthDifference );
	}

	adjustFixedAndDynamicWidths( fixedWidthDifference, dynamicWidthDifference ) {
		const im = ItmMgr.getInst();
		if ( Validator.isValidNumber( fixedWidthDifference ) ) {
			this.wdtFix += fixedWidthDifference;
			im.setFlexWdt( this.ccnFix, this.wdtFix, true );
			XtwUtils.syncZeroWidthClass( this.ccnFix, this.wdtFix );
		}
		if ( Validator.isValidNumber( dynamicWidthDifference ) ) {
			this.wdtDyn += dynamicWidthDifference;
			im.setFlexWdt( this.scrCnt, this.wdtDyn, true );
			im.setFlexWdt( this.ccnDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.scrCnt, this.wdtDyn );
			XtwUtils.syncZeroWidthClass( this.ccnDyn, this.wdtDyn );
		}
		return true;
	}

	/**
	 * updates the column order after a move operation
	 * @param {XtwCol} movedColumn the moved column
	 * @param {XtwCol} targetColumn the target column
	 * @param {Boolean} before indicates whether the moved column should be inserted before the second column
	 * @returns {Boolean} success
	 */
	updateColumnOrder( movedColumn, targetColumn, before = true ) {
		if ( [ movedColumn, targetColumn ].some( column => !(column instanceof XtwCol) ) ) {
			return false;
		}
		if ( movedColumn === targetColumn ) {
			// nothing to do
			return true;
		}
		// 1. update ordered column arrays
		const from_array = movedColumn.fix ? this.ordCol.fix : this.ordCol.dyn;
		const from_idx = from_array.indexOf(movedColumn);
		if ( from_idx !== -1 ) {
			from_array.splice(from_idx, 1);
		} else {
			this.warn('Could not find moved column in its container!', movedColumn, from_array);
		}
		const to_array = targetColumn.fix ? this.ordCol.fix : this.ordCol.dyn;
		const to_idx = to_array.indexOf(targetColumn);
		if ( to_idx !== -1 ) {
			to_array.splice(to_idx + (before ? 0 : 1), 0, movedColumn);
		} else {
			this.warn('Could not find target column in its container!', targetColumn, to_array);
			to_array.push(movedColumn);
		}
		// 2. re-create column map
		this.recreateColumnMap();
		// done
		return true;
	}

	/**
	 * re-creates the column map based on the current column order
	 */
	recreateColumnMap() {
		const cols = this.columns;
		cols.clear();
		const containers = [ this.ordCol.fix, this.ordCol.dyn ];
		for ( let cont of containers ) {
			for ( let c of cont ) {
				cols.addObj(c.id, c);
			}
		}
	}

	resetColumnsDefaultProperties( orderedColumnsProperties ) {
		if ( !Validator.isObject( orderedColumnsProperties ) ) {
			return false;
		}
		return this.resetColumnsWidthsAndVisibility( orderedColumnsProperties.properties );
	}

	resetColumnsOrder( { fixedOrder, dynamicOrder } ) {
		const columns = [];
		[ fixedOrder, dynamicOrder ].forEach( ( order, index ) => {
			const isFixed = index <= 0;
			if ( !Validator.isArray( order, true ) ) {
				return;
			}
			order.forEach( id => {
				const columnId = Number( id );
				if ( !Validator.isPositiveInteger( columnId ) ) {
					return;
				}
				columns.push( { idc: columnId, fix: isFixed } );
			} );
		} );
		if ( !Validator.isArray( columns, true ) ) {
			return false;
		}
		this.restoreColumnOrder( { cols: columns } );
		return true;
	}

	resetColumnsWidthsAndVisibility( columnsProperties ) {
		if ( !Validator.isObject( columnsProperties ) ) {
			return false;
		}
		let allDescribedColumns = Object.entries( columnsProperties );
		if ( !Validator.isIterable( allDescribedColumns ) ) {
			return false;
		}
		allDescribedColumns = [ ...allDescribedColumns ];
		let successfullyProcessedColumns = 0;
		for ( let describedColumn of Object.entries( columnsProperties ) ) {
			if ( !this.resetColumnWidthAndVisibility( describedColumn ) ) {
				continue;
			}
			successfullyProcessedColumns++;
		}
		return successfullyProcessedColumns == allDescribedColumns.length;
	}

	resetColumnWidthAndVisibility( describedColumn ) {
		if ( !Validator.isArray( describedColumn ) ||
			describedColumn.length < 2 ) {
			return false;
		}
		const columnId = Number( describedColumn[ 0 ] );
		if ( !Validator.isPositiveInteger( columnId ) ) {
			return false;
		}
		const properties = describedColumn[ 1 ];
		if ( !Validator.isObject( properties ) ) {
			return false;
		}
		let atLeastSomethingSet = false;
		if ( Validator.isBoolean( properties.isVisible ) ) {
			atLeastSomethingSet = true;
			this.set_vis( { idc: columnId, vis: properties.isVisible } );
		}
		if ( Validator.isBoolean( properties.isAvailable ) ) {
			atLeastSomethingSet = true;
			this.set_avl( { idc: columnId, avl: properties.isAvailable } );
		}
		if ( Validator.isPositiveInteger( properties.width ) ) {
			atLeastSomethingSet = true;
			this.set_width( { idc: columnId, width: properties.width } );
		}
		return atLeastSomethingSet;
	}

	/**
	 * sends a column order notification to the web server
	 */
	notifyColumnOrder() {
		const par = {};
		par.cols = [];
		const cnts = [ this.ccnFix, this.ccnDyn ];
		cnts.forEach( ( cont ) => {
			const cc = cont.children.length;
			for ( let i = 0; i < cc; ++i ) {
				const ce = cont.children[ i ];
				if ( ce.__cid > 0 ) {
					par.cols.push( { idc: ce.__cid, fix: !!ce.__fix } );
				}
			}
		} );
		this._nfySrv( 'columnOrder', par );
	}

	sortTitleColumn( evt, targetColumn ) {
		if ( !( targetColumn instanceof XtwCol ) ) {
			this.voidMousedownProperties();
			return this.voidMovingProperties( false );
		}
		if ( !this.mousedownAndMouseupHappenedOnSameSpot( evt ) ) {
			this.voidMousedownProperties();
			return this.voidMovingProperties( false );
		}
		const isSortedDescending = targetColumn.toggleSortingState();
		const otherColumnsVoided = this.voidOtherColumnsSortingStates( targetColumn );
		if ( this.ccnDyn instanceof HTMLElement &&
			Validator.is( this.xtwBody, "XtwBody" ) &&
			Validator.isFunction( this.xtwBody.addHorizontalScrollAfterModelData ) ) {
			this.xtwBody.addHorizontalScrollAfterModelData( evt, "onSortColumn-" );
		}
		this.setSortColumn( targetColumn.id, isSortedDescending, true );
		this.voidMousedownProperties();
		this.voidMovingProperties();
		return otherColumnsVoided;
	}

	mousedownAndMouseupHappenedOnSameSpot( mouseUpEvent ) {
		if ( !( mouseUpEvent instanceof MouseEvent ) || !( this.mouseDownEvent instanceof MouseEvent ) ) {
			return false;
		}
		const xAxisDifference = Math.abs( this.mouseDownEvent.clientX - mouseUpEvent.clientX );
		return xAxisDifference < 3;
	}

	voidOtherColumnsSortingStates( targetColumn ) {
		const columns = this.columnsArray;
		for ( let column of columns ) {
			if ( !(column instanceof XtwCol) || (column === targetColumn) ) {
				continue;
			}
			column.voidSortingState();
		}
		return true;
	}

	/**
	 * returns an array of all columns
	 * @param {Boolean} vis if true, then the visible order must be considered
	 * @return {XtwCol[] | Object} an array of all columns if "vis" is false or an object providing fixed and dynamic columns
	 */
	getColumns( vis ) {
		if ( vis ) {
			return this.ordCol;
		} else {
			// just return the raw collection
			return this.columns.getValues();
		}
	}

	/**
	 * returns a flat array of all currently visible columns
	 * @returns {XtwCol[]} a flat array of all currently visible columns
	 */
	getVisibleColumns() {
		const cols = [];
		const oc = this.ordCol;
		if ( oc.fix && (oc.fix.length > 0) ) {
			for ( const c of oc.fix ) {
				if ( c.available && c.visible ) {
					cols.push(c);
				}
			}
		}
		if ( oc.dyn && (oc.dyn.length > 0) ) {
			for ( const c of oc.dyn ) {
				if ( c.available && c.visible ) {
					cols.push(c);
				}
			}
		}
		return cols;
	}

	/**
	 * sets the table body widget
	 * @param {XtwBody} xtb the table body widget
	 */
	setTblBody( xtb ) {
		this.xtwBody = xtb;
	}

	/**
	 * sets the sort order
	 * @param {Number} idc column ID
	 * @param {Boolean} desc "descending" flag; false: ascending sort order; true: descending sort order
	 * @param {Boolean} nfy flag whether to notify the web server
	 */
	setSortColumn( idc, desc, nfy ) {
		if ( this.alive && ( typeof idc === 'number' ) ) {
			if ( idc < 0 ) {
				this.voidOtherColumnsSortingStates();
			}
			const cols = this.columns;
			let eff_idc = -1;
			let eff_desc = !!desc;
			if ( ( idc > 0 ) && cols.hasObj( idc ) ) {
				eff_idc = idc;
			} else {
				eff_desc = false;
			}
			this.sortColumn = eff_idc;
			this.sortDirection = eff_desc;
			cols.forEach( ( c ) => {
				if ( !c.select ) {
					c.setSortingState( c.id === eff_idc ? eff_desc : null );
				}
			} );
			if ( nfy && ( eff_idc > 0 ) ) {
				// notify table body
				this._nfyTblBody();
				// notify web server
				const par = { idc: eff_idc, direction: eff_desc, hsc: this.hscPos };
				this._nfySrv( 'sortOrder', par );
			}
		}
	}

	/**
	 * @returns {Number} the ID of the current sorting column
	 */
	getSortColumn() {
		return this.sortColumn;
	}

	/**
	 * @returns {Boolean} the current sorting direction
	 */
	getSortDirection() {
		return this.sortDirection;
	}

	get bodyClientRect() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) || !( "clientRect" in xtwBody ) ) {
			return void 0;
		}
		return xtwBody.clientRect;
	}

	get headClientRect() {
		if ( !this.isRendered ) {
			return void 0;
			// return new DOMRect();
		}
		return this.element.getBoundingClientRect();
	}

	get fixedContainerRect() {
		if ( !( this.ccnFix instanceof HTMLElement ) ) {
			return void 0;
			// return new DOMRect();
		}
		return this.ccnFix.getBoundingClientRect();
	}

	get dynamicContainerRect() {
		if ( !( this.ccnDyn instanceof HTMLElement ) ) {
			return void 0;
			// return new DOMRect();
		}
		return this.ccnDyn.getBoundingClientRect();
	}

	get headClientWidth() {
		const headClientRect = this.headClientRect;
		if ( !( headClientRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( headClientRect.width );
	}

	get headClientHeight() {
		const headClientRect = this.headClientRect;
		if ( !( headClientRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( headClientRect.height );
	}

	set headClientHeight( heightInPixels ) {
		if ( this.xtdTbl instanceof XtwTbl ) {
			this.xtdTbl.headClientHeight = heightInPixels;
		}
	}

	get fixedContainerWidth() {
		const fixedContainerRect = this.fixedContainerRect;
		if ( !( fixedContainerRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( fixedContainerRect.width );
	}

	get dynamicContainerWidth() {
		const dynamicContainerRect = this.dynamicContainerRect;
		if ( !( dynamicContainerRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( dynamicContainerRect.width );
	}

	get isHorizontalScrollingNecessary() {
		return this.headClientWidth < this.fixedContainerWidth + this.dynamicContainerWidth;
	}

	/**
	 * sets the common text color
	 * @param {Object} args parameter object
	 */
	setTxc( args ) {
		const txc = args.txc || null;
		this.clrTxt = txc;
		this._applyTxc();
	}

	/**
	 * sets the common background color
	 * @param {Object} args parameter object
	 */
	setBgc( args ) {
		if ( !Validator.isObject( args ) || !Validator.isArray( args.bgc ) ) {
			this.setBackgroundColor( null, false );
			return;
		}
		const color = XtwUtils.colorArrayToRgba( args.bgc );
		this.setBackgroundColor( color, true );
	}

	setBackgroundColor( color, validate = true ) {
		if ( !!validate && !Validator.isString( color ) ) {
			return false;
		}
		[ this.ccnFix, this.ccnDyn, this.element ].forEach( element => {
			if ( !( element instanceof HTMLElement ) ) {
				return;
			}
			element.style.setProperty( OWN_BACKGROUND_COLOR, color );
		} );
		return true;
	}

	/**
	 * sets new content of a column
	 * @param {Object} args parameter object providing column ID and new content
	 */
	set_ctt( args ) {
		const idc = this._colID( args.idc );
		const ctt = args.ctt || CellCtt.EMPTY_CONTENT;
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.ctt = ctt;
			col.update();
			this._dropFieldMenu();
			const name = this._getRequestId(RDR_COLUMNUPD);
			if ( !this.renderRqu.hasRequest(name) ) {
				const self = this;
				this.renderRqu.addRequest(name, () => {
					if ( self.ready && self.alive && self.xtwBody ) {
						self.xtwBody.onColumnChanged();
					}
				} );
			}
		}
	}

	/**
	 * sets the content alignment of a column
	 * @param {Object} args parameter object providing column ID and new alignment
	 */
	set_aln( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.setAlignment(args.aln || '');
		}
	}

	toggleColumnVisibility( columnId ) {
		const column = this.getColumn( columnId );
		if ( !(column instanceof XtwCol) ) {
			return false;
		}
		column.visible = !column.visible;
		column.update();
		return true;
	}

	/**
	 * changes the visibility of a column
	 * @param {Object} args parameter object providing column ID and the visibility flag
	 */
	set_vis( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( !(col instanceof XtwCol) ) {
			return false;
		}
		const vis = ( args.vis !== undefined ) ? !!args.vis : true;
		if ( vis === col.visible ) {
			return false;
		}
		if ( this.isTraceEnabled() && vis && !col.available ) {
			this.trace(`A column that is not available` + ` is being set as visible.` );
		}
		const keep_mnu = !!args.keep_mnu;
		if ( !keep_mnu ) {
			this._dropFieldMenu();
		}
		col.visible = vis;
		this.log(`Changing column ${idc} to visibility="${vis}"`);
		this._triggerHeaderRebuild();
	}

	/**
	 * changes the "available" state of a column; if it becomes unavailable, the it becomes invisible as well
	 * @param {Object} args parameter object providing column ID and the availability flag
	 */
	set_avl( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col ) {
			const avl = ( args.avl !== undefined ) ? !!args.avl : true;
			if ( avl !== col.available ) {
				this.log(`Changing column ${idc} to available="${avl}"`);
				this._dropFieldMenu();
				const vis = col.visible;
				col.available = avl;
				if ( !avl && vis ) {
					const va = { idc: args.idc, vis: false };
					this.set_vis( va );
				} else {
					// a column that was not available before became available so we have to re-build everything
					this._triggerHeaderRebuild();
				}
			}
		}
	}

	/**
	 * sets new width of a column
	 * @param {Object} args parameter object providing column ID and new width
	 */
	 set_width( args ) {
		const idc = this._colID( args.idc );
		const wdt = args.width || 0;
		const col = this.columns.getObj( idc );
		if ( col instanceof XtwCol ) {
			if ( !this._hrbPending ) {
				// call helper method, does all required stuff
				this.log(`Setting column ${idc} to width="${wdt}" immediately.`);
				col.setWidth(wdt);
			}
			else {
				// just set the width property
				this.log(`Changing column ${idc} to width="${wdt}" for later processing.`);
				col.width = wdt;
			}
		}
	}

	/**
	 * sets the default data font of a column
	 * @param {Object} args parameter object providing column ID and the data font descriptor
	 */
	set_datfnt( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.dataFont = args.font || null;
		}
	}

	/**
	 * sets the data alignment of a column
	 * @param {Object} args parameter object providing column ID and the data alignment
	 */
	set_dataln( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col instanceof XtwCol ) {
			col.dataAlign = args.aln || '';
		}
	}

	/**
	 * sets the default data color of a column
	 * @param {Object} args parameter object providing column ID and the default data
	 */
	set_dattxc( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( !(col instanceof XtwCol) ) {
			return false;
		}
		return col.setDataFontColor( args );
	}

	set_datbgc( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( !(col instanceof XtwCol) ) {
			return false;
		}
		return col.setDataBackgroundColor( args );
	}

	/**
	 * sets the hyperlink flag of a column
	 * @param {Object} args parameter object providing column ID and the hyperlink flag
	 */
	set_link( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( (col instanceof XtwCol) && !col.select ) {
			col.link = !!args.link;
		}
	}

	/**
	 * sets new secondary image of a column
	 * @param {Object} args parameter object providing column ID and new secondary image
	 */
	set_sim( args ) {
		const idc = this._colID( args.idc );
		const img = args.sim || null;
		const col = this.columns.getObj( idc );
		if ( col instanceof XtwCol ) {
			col.secImg = img;
			col.update();
		}
	}

	/**
	 * creates a new column
	 * @param {Object} args parameter object providing the column ID of the new column and optionally more parameters
	 */
	addCol( args ) {
		this._dropFieldMenu();
		const idc = this._colID( args.idc );
		if ( !idc ) {
			return;
		}
		const ref = args.ref || 0;
		const col = new XtwCol( this, idc, args );
		col.clrBrd = this.xtdTbl instanceof XtwTbl ? this.xtdTbl.clrVtg : null;
		this.columns.addObj( idc, col );
		const cc = col.fix ? this.ordCol.fix : this.ordCol.dyn;
		if ( ref > 0 ) {
			const idx = cc.findIndex( c => c.id === ref );
			if ( idx !== -1 ) {
				// ok, insert it
				cc.splice( idx + 1, 0, col );
			} else {
				// ?!?! - add it anyway
				cc.push( col );
			}
			// we *must* rebuild the column map!!!
			this._rebuildColumnMap();
		} else {
			// just add the column
			cc.push( col );
		}
		const rowTemplateAvailable = this.hasRowTpl;
		const currentViewIsRowTemplate = rowTemplateAvailable && this.isRowTpl;
		if ( this.ready && !currentViewIsRowTemplate ) {
			if ( ref > 0 ) {
				// full refresh
				this._rebuildHeader();
				this.xtwBody.rebuildAllRows();
			} else {
				// just render the column
				this._renderCol( col );
			}
		} else if ( rowTemplateAvailable ) {
			this._regRtpCol( idc, col );
		}
	}

	/**
	 * renders/updates the column headers in the table/excel display/view
	 */
	renderColumns() {
		this._rebuildHeader();
	}

	/**
	 * ensures that a column is visible
	 * @param {Object} args parameter object providing the column ID
	 */
	ensureVisible( args ) {
		const idc = this._colID( args.idc );
		return this.scrollToColumn( this.columns.getObj( idc ), true );
	}

	/**
	 * called by the web server after column visibility changes were processed
	 * @param {*} args arguments
	 */
	afterColumnChanged(args) {
		this._runRfrCallback();
	}

	/**
	 * restores a previously stored column order
	 * @param {Object} args parameter object providing an array specifying the column order
	 */
	restoreColumnOrder( args ) {
		if ( !this.ready || this.isRowTpl ) {
			// not yet, please!
			this.rcoArgs = args;
			return;
		}
		const cols = args.cols || [];
		if ( cols.length > 0 ) {
			// ensure that all columns are rendered
			this._renderAllCols(true);
			// collect all columns in a special map
			const col_map = new Map();
			try {
				this.ordCol.fix = [];
				this.ordCol.dyn = [];
				const ocf = this.ordCol.fix;
				const ocd = this.ordCol.dyn;
				this.columns.forEach( ( c ) => {
					if ( c.alive ) {
						if ( !c.select ) {
							// store column in the map
							col_map.set( c.id, c )
							if ( c.element instanceof HTMLElement ) {
								// and remove it's DOM element temporarily from DOM, if present
								HtmHelper.rmvDomElm(c.element);
							}
						} else {
							ocf.push( c );
						}
					}
				} );
				const dcf = this.ccnFix;
				const dcd = this.ccnDyn;
				cols.forEach( ( ci ) => {
					const col = col_map.get( ci.idc );
					if ( col ) {
						col_map.delete( ci.idc );
						const fix = !!ci.fix;
						const dcont = fix ? dcf : dcd;
						const ocont = fix ? ocf : ocd;
						col.fix = fix;
						if ( col.element ) {
							col.element.__fix = fix;
							dcont.appendChild( col.element );
						}
						ocont.push( col );
					}
				} );
				if ( col_map.size > 0 ) {
					// there are remaining columns
					col_map.forEach( ( col ) => {
						const fix = !!col.fix;
						const dcont = fix ? dcf : dcd;
						const ocont = fix ? ocf : ocd;
						if ( col.element ) {
							col.element.__fix = fix;
							dcont.appendChild( col.element );
						}
						ocont.push( col );
					} );
				}
			} finally {
				col_map.clear();
			}
			this._renderAllCols(false); // yes, once again - that will correct the widths of bot column containers etc.
			if ( this.xtwBody ) {
				// the body widget must rebuild all row items
				this.xtwBody.rebuildAllRows();
			}
			// send back effective column order
			this.notifyColumnOrder();
		}
	}

	/**
	 * registers a column for row template processing
	 * @param {Number} id column ID
	 * @param {XtwCol} col the column
	 */
	_regRtpCol( id, col ) {
		if ( ( id > 0 ) && col ) {
			const sid = String( id );
			if ( !this.rtpColumns ) {
				this.rtpColumns = new Map();
			}
			this.rtpColumns.set( sid, col );
		}
	}

	/**
	 * called by the web server if it needs the currently required header width
	 */
	rptHdrWidth() {
		const cols = this.columns.getValues();
		let wdt = 0;
		cols.forEach( ( c ) => {
			wdt += c.getWidth();
		} );
		const par = {}
		par.width = wdt;
		this._nfySrv( 'headerWidth', par );
	}

	_init() {
		const elm = this.element;
		const sc = this.scrCnt;
		const cf = this.ccnFix;
		const cd = this.ccnDyn;

		elm.style.position = 'absolute';
		elm.style.display = 'flex';
		elm.style.flexDirection = 'row';
		elm.style.flexWrap = 'nowrap';
		elm.style.overflow = 'hidden';

		sc.style.height = 'inherit';
		sc.style.overflow = 'hidden';

		cf.style.display = 'flex';
		cf.style.flexDirection = 'row';
		cf.style.flexWrap = 'nowrap';
		cf.style.overflow = 'hidden';
		cf.style.height = 'inherit';

		cd.style.position = 'relative';
		cd.style.display = 'flex';
		cd.style.flexDirection = 'row';
		cd.style.flexWrap = 'nowrap';
		cd.style.overflow = 'hidden';
		cd.style.height = 'inherit';

		this._applyTxc();
	}

	/**
	 * creates a render request ID from a name
	 * @param {String} name the actual request name
	 * @returns the render request ID
	 */
	_getRequestId(name) {
		return this.wdgId + '-' + name;
	}

	/**
	 * ensures that the column ID's type is "number"
	 * @param {*} id column ID
	 * @returns {Number} the effective number ID of type "number"
	 */
	_colID( id ) {
		if ( typeof id !== 'number' ) {
			return 0;
		}
		return Number( id );
	}

	/**
	 * attaches special event handlers
	 */
	_attachEventHandlers() {
		const parentElement = this.parentElement;
		new ExternalEventsTransferManager( this, parentElement );
		if ( parentElement instanceof HTMLElement ) {
			parentElement.classList.add( "xtwhead-container" );
			HtmHelper.removeStyleProperty( parentElement, "height" );
			if ( parentElement.dataset instanceof DOMStringMap ) {
				parentElement.dataset.class = "XtwHead container";
				parentElement.dataset.idstr = this.wdgIdStr;
			}
		}
		this.addListeners();

		// hook in RAP drag'n'drop handling
		const self = this;
		const rap_wdg = rwt.remote.ObjectRegistry.getObject(this.wdgId);
		if ( rap_wdg && (typeof rap_wdg.addEventListener === 'function') ) {
			rap_wdg.addEventListener('dragmove', (re) => self._onDragMove(re), self);
			rap_wdg.addEventListener('dragout', (re) => self._onDragOut(re), self);
			rap_wdg.addEventListener('dragdrop', (re) => self._onDragDrop(re), self);
		}
	}

	/**
	 * registers a "refresh" callback function
	 * @param {Function} cf callback function
	 */
	_setRfrCallback(cf) {
		if ( typeof cf === 'function' ) {
			this._rfrCallback = cf;
		}
	}

	_runRfrCallback() {
		try {
			const cf = this._rfrCallback;
			this._rfrCallback = null;
			if ( typeof cf === 'function' ) {
				cf();
			}
		}
		finally {
			this._rfrCallback = null;
		}
	}

	_triggerHeaderRebuild() {
		if ( !this._hrbPending ) {
			const self = this;
			const rid = this._getRequestId(RDR_REBUILDHDR);
			this._hrbPending = UIRefresh.getInstance().addRequest(rid, () => {
				self._fullHeaderRebuild();
			});
		}
	}

	_fullHeaderRebuild() {
		try {
			if ( this.alive ) {
				const self = this;
				HtmHelper.runUnconnected(this.element, () => {
					self._doFullHeaderRebuild();
				});
			}
		} finally {
			this._hrbPending = false;
		}
	}

	_doFullHeaderRebuild() {
		this._hrbPending = false;
		this._dropFieldMenu();
		this._rebuildHeader();
		this._triggerColumnFit();
		this.xtwBody.rebuildAllRows();
		this.xtdTbl.setHScrollPos(0);
	}

	/**
	 * (re-)builds the table header rendering all columns and restoring column order
	 */
	_rebuildHeader() {
		this._renderAllCols(true);
		if ( this.rcoArgs ) {
			const args = this.rcoArgs;
			delete this.rcoArgs;
			this.restoreColumnOrder( args );
		}
		this.addContextMenuListener();
	}

	/**
	 * re-builds the column map according to the current / new column order
	 */
	_rebuildColumnMap() {
		const columns = this.columns;
		columns.clear();
		const ccs = [ this.ordCol.fix, this.ordCol.dyn ];
		ccs.forEach( ( cc ) => {
			cc.forEach( ( col ) => {
				columns.addObj( col.id, col );
			} );
		} );
	}

	/**
	 * renders all columns and updates internal widths
	 * @param {Boolean} fcu force column update flag
	 */
	_renderAllCols(fcu = true) {
		if ( this.isRowTpl || !this.ready || !this.element ) {
			return;
		}
		let fxw = 0;
		let dnw = 0;
		const self = this;
		const ccs = [ this.ordCol.fix, this.ordCol.dyn ];
		ccs.forEach( ( cc ) => {
			cc.forEach( ( column ) => {
				if ( !(column instanceof XtwCol) || !column.alive ) {
					// ?!
					this.warn('Got an invalid column object:', column);
					return;
				}
				if ( column.element instanceof HTMLElement ) {
					// remove from DOM - will be added later...
					HtmHelper.rmvDomElm(column.element);
				}
				if ( !column.available ) {
					return;
				}
				// we *must* render (or re-add) each available column, regardless of width and/or visibility
				if ( fcu ) {
					column.update();
				}
				self._renderCol( column );
				if ( column.fix ) {
					fxw += column.getWidth();
				} else {
					dnw += column.getWidth();
				}
			} );
		} );
		const im = ItmMgr.getInst();
		im.setFlexWdt( this.ccnFix, fxw, true );
		XtwUtils.syncZeroWidthClass( this.ccnFix, fxw );
		this.wdtFix = fxw;
		im.setFlexWdt( this.scrCnt, dnw, true );
		XtwUtils.syncZeroWidthClass( this.scrCnt, dnw );
		im.setFlexWdt( this.ccnDyn, dnw, true );
		XtwUtils.syncZeroWidthClass( this.ccnDyn, dnw );
		this.wdtDyn = dnw;
		if ( this.xtdTbl instanceof XtwTbl ) {
			this.xtdTbl.setPartWdt( fxw, dnw );
		}
		// tell the server to maybe re-think its decision to hide or show the
		// "scroll widgets" after all visible columns were rendered
		this.rptHdrWidth();
	}

	/**
	 * renders a column
	 * @param {XtwCol} c the column to be rendered
	 */
	_renderCol( c ) {
		if ( c.available && this.element ) {
			const pe = c.fix ? this.ccnFix : this.ccnDyn;
			if ( !c.element ) {
				// create a DIV element for the column itself
				const ce = document.createElement( 'div' );
				c.render( ce );
				pe.appendChild( ce );
			} else {
				// re-add it
				const ce = c.element;
				HtmHelper.rmvDomElm(ce);
				pe.appendChild( ce );
				XtwUtils.syncZeroWidthClass( ce, c.width );
			}
		}
	}

	/**
	 * applies the common text color
	 */
	_applyTxc() {
		if ( !( this.element instanceof HTMLElement ) ) {
			return;
		}
		let color = XtwUtils.colorArrayToRgba( this.clrTxt );
		if ( !Validator.isString( color ) ) {
			color = null;
		}
		this.element.style.setProperty( OWN_TEXT_COLOR, color );
	}

	/**
	 * notifies the table body about relevant changes
	 */
	_nfyTblBody() {
		const xtb = this.xtwBody;
		if ( xtb instanceof XtwBody ) {
			xtb.onTableChange();
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv( code, par ) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject( this ).notify( "PSA_XTW_HDR_NFY", param );
		}
	}

	_triggerColumnFit() {
		if ( this.ready && this.renderRqu ) {
			const name = this._getRequestId(RDR_COLUMNFIT);
			const rr = this.renderRqu;
			if ( !rr.hasRequest(name) ) {
				const self = this;
				rr.addRequest(name, () => {
					self._ensureColumnFit();
				});
			}
		}
	}

	_ensureColumnFit() {
		if ( this.ready && this.xtwBody ) {
			this.xtwBody.ensureAutoFitOnTableColumns();
		}
	}

	/**
	 * called if data are dragged over this instance
	 * @param {*} re a RAP event describing the drag operation
	 */
	_onDragMove(re) {
		if ( this.alive && (this.xtdTbl instanceof XtwTbl) ) {
			// we're on the header - only upwards
			this.xtdTbl.startAutoScroll(true);
		}
	}

	/**
	 * called if a drag'n'drop operation leaves this instance
	 * @param {*} re a RAP event describing the drag operation
	 */
	_onDragOut(re) {
		this._stopAutoScroll();
	}

	/**
	 * called if drag'n'drop data were dropped
	 * @param {*} re a RAP event describing the drag operation
	 */
	_onDragDrop(re) {
		this._stopAutoScroll();
	}

	/**
	 * tells the table widget to stop any auto scrolling
	 */
	_stopAutoScroll() {
		if ( this.xtdTbl instanceof XtwTbl ) {
			this.log('Stopping auto scrolling.');
			this.xtdTbl.stopAutoScroll();
		}
	}


	handleTransferredEvent( domEvent ) {
		if ( XtwUtils.isArrowUp( domEvent ) || XtwUtils.isArrowDown( domEvent ) ) {
			if ( !Validator.is( this.xtwBody, "XtwBody" ) ||
				!Validator.isFunction( this.xtwBody.handleTransferredEvent ) ) {
				this.log(`The DOM arrow navigation event could not be` +
					` transferred from the table header widget to the table body` +
					` widget, because the table body widget does not have a` +
					` method to handle transferred DOM events.` );
				return false;
			}
			return this.xtwBody.handleTransferredEvent( domEvent )
		}
	}

	changeColumnTooltip( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const column = this.getColumn( parameters.idc );
		if ( !(column instanceof XtwCol) ) {
			return false;
		}
		return column.setTooltip( parameters.tooltip );
	}

	addSumToColumnTooltip( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const column = this.getColumn( parameters.idc );
		if ( !(column instanceof XtwCol) ) {
			return false;
		}
		return column.addSumToTooltip( parameters.sum );
	}

	setColumnEditingPen( parameters ) {
		if ( !Validator.isObject( parameters ) ||
			!Validator.isBoolean( parameters.hasEditingPen ) ) {
			return false;
		}
		const column = this.getColumn( parameters.idc );
		if ( !Validator.isObject( column ) || !( "hasEditingPen" in column ) ) {
			return false;
		}
		column.hasEditingPen = parameters.hasEditingPen;
	}

	/** register custom widget type */
	static register() {
		console.debug( 'Registering custom widget XtwHead.' );
		rap.registerTypeHandler( 'psawidget.XtwHead', {
			factory: function ( properties ) {
				return new XtwHead( properties );
			},
			destructor: 'destroy',
			properties: [ 'txc', 'bgc', 'scb' ],
			methods: [ 'addCol', 'rptHdrWidth', 'set_ctt', 'set_aln',
				'set_width', 'set_vis', 'set_avl', 'set_datfnt', 'set_dataln', 'set_dattxc', 'set_datbgc',
				'set_link', 'set_sim', 'renderColumns', 'ensureVisible', 'afterColumnChanged', 'restoreColumnOrder',
				'changeColumnTooltip', 'addSumToColumnTooltip', "setColumnEditingPen"
			],
			events: [ "PSA_XTW_HDR_NFY" ]
		} );
	}
}

console.debug( "widgets/xtw/XtwHead.js loaded." );
