import PSA from '../../../psa';
import Checkbox from '../impl/editing/Checkbox';
import TripleStateCheckbox from '../impl/editing/TripleStateCheckbox';
import Color from '../../../utils/Color';
import HtmHelper from '../../../utils/HtmHelper';
import ItmMgr from '../../../gui/ItmMgr';
import Validator from '../../../utils/Validator';
import XItem from './XItem';
import XtwCol from './XtwCol';
import XRowItem from './XRowItem';
import XtwTooltipListenerExtension from '../util/XtwTooltipListenerExtension';
import EditableElement from '../impl/editing/EditableElement';
import XtwCellEditingExtension from '../impl/editing/XtwCellEditingExtension';
import XtwSelectionCellInsertionDummyExtension from '../impl/editing/XtwSelectionCellInsertionDummyExtension';
import SelectionCellRowHeightExtension from '../impl/rowheight/SelectionCellRowHeightExtension';
import XtwUtils from '../util/XtwUtils';
import CellCtt from '../model/CellCtt';
import { EMPTY_CTT } from '../model/CellCtt';
import MRowItem from '../model/MRowItem';
import MCell from '../model/MCell';
import MDataRow from '../model/MDataRow';
import DomEventHelper from '../../../utils/DomEventHelper';
import XtwBody from '../XtwBody';

export const OWN_BACKGROUND_COLOR = "--rtp-own-background-color";
export const ITEM_SPECIFIC_TEXT_COLOR = "--rtp-item-specific-text-color";
export const ITEM_SPECIFIC_BACKGROUND_COLOR = "--rtp-item-specific-background-color";
const ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR = "--rtp-item-specific-alternative-background-color";
const ITEM_SPECIFIC_SELECT_BGC = '--rtp-item-specific-select-bgc';

/**
 * a cell item in the UI
 */
export default class XCellItem extends XItem {

	/**
	 * constructs a new instance
	 * @param {XtwCol} col the column
	 * @param {XRowItem} row the row
	 * @param {*} brc border color
	 */
	constructor( col, row, brc ) {
		super('widgets.xtw.XCellItem');
		this._idc = col.id;
		this._idr = row.idr;
		this._column = col;
		this._row = row;
		this.select = !!this.column.select;
		this.defBrc = brc || null;
		this._alignElm = null;
		this._focusElm = null;
		this._droppedDown = false;
		this.ctt = CellCtt.EMPTY_CONTENT;
		this._onTooltipTimer = PSA.getInst().bind( this, this.onTooltipTimer );
		new XtwTooltipListenerExtension( this );
		new XtwCellEditingExtension( this );
		if ( this.isLogicDataType ) {
			this.isMandatory ? new Checkbox( this ) : new TripleStateCheckbox( this );
		}
		const elm = this.getDomElement();
		elm.className = 'xtwcell';
		if ( this.select ) {
			this._iniSelectCll( elm );
			new XtwSelectionCellInsertionDummyExtension( this );
			new SelectionCellRowHeightExtension( this );
		} else {
			this._initDataCell( elm );
		}
		if ( col.link ) {
			elm.classList.add( "rtp-link" );
		}
		elm.__psaidc = this.idc;
		this.addListeners( elm );
		this.cellwdt = 0;
		this.cellFont = null;
		this.setWidth( col.getWidth() );
		this._insertMode = false;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	doDestroy() {
		this.removeAllListeners();
		if ( Validator.isFunction( this.destroyHeightAdjustmentDiv ) ) {
			this.destroyHeightAdjustmentDiv();
		}
		delete this.inputField;
		delete this.textarea;
		delete this.ctt;
		delete this.defBrc;
		delete this.select;
		delete this._idc;
		delete this._idr;
		delete this._column;
		delete this._row;
		delete this.cellwdt;
		delete this.cellFont;
		delete this.cttElm;
		delete this._alignElm;
		super.doDestroy();
	}

	/**
	 * @returns {Number} the column ID
	 */
	get idc() {
		return this._idc;
	}

	/**
	 * @returns {Number} the row ID
	 */
	get idr() {
		return this._idr;
	}

	/**
	 * @returns {XtwCol} the column
	 */
	get column() {
		return this._column;
	}

	/**
	 * @returns {XRowItem} the row item to which this cell belongs
	 */
	get row() {
		return this._row;
	}

	get _im() {
		return ItmMgr.getInst();
	}

	/**
	 * adds necessary listeners to the HTM element
	 * @param {HTMLElement} element the element to whom the listener is added
	 */
	addListeners( element ) {
		this.addTooltipListeners( element );
		return this.addContextMenuListener( element );
	}

	/**
	 * gets if this cell is of type "link" or not, which is directly dependent
	 * on whether or not the column (XtwCol) corresponding/assigned to this cell
	 * is of type "link" or not
	 * @return {Boolean} true if the cell is of type link, false otherwise
	 */
	get link() {
		return (this.column instanceof XtwCol) && !!this.column.link;
	}

	/**
	 * gets if this cell is fixed or not, which is directly dependent on whether
	 * or not the column (XtwCol) corresponding/assigned to this cell is fixed
	 * or not
	 * @return true if the cell is fixed, false if it is not and "undefined" if
	 * there is no column
	 */
	get fix() {
		return (this.column instanceof XtwCol) ? !!this.column.fix : void 0;
	}

	/**
	 * @returns {HTMLElement} the "focus marker" element
	 */
	get focusElement() {
		return this._focusElm;
	}

	/**
	 * @returns {HTMLElement} the "alignment" element
	 */
	get alignElement() {
		return this._alignElm;
	}

	/**
	 * @returns {Boolean} true if there's a cell editor in dropped down state; false otherwise
	 */
	get droppedDown() {
		return this._droppedDown;
	}

	/**
	 * sets the "dropped down" state
	 * @param {Boolean} new "dropped down" state
	 */
	set droppedDown(drp) {
		this._droppedDown = !!drp;
	}

	/**
	 * @returns {Boolean} true if the current edit mode is an "insert" mode; false otherwise
	 */
	get insertMode() {
		return this._insertMode;
	}

	/**
	 * @param {Boolean} mode "insert" mode flag
	 */
	set insertMode(mode) {
		this._insertMode = !!mode;
	}

	/**
	 * returns the cell access mode
	 * 0: invisible, inaccessible
	 * 1: "skip", visible but inaccessible
	 * 2: read/only
	 * 3: writable
	 * 4: mandatory
	 * @returns {Number} cell access mode
	 */
	get cellAccessMode() {
		// default cell access mode is writable (3)
		let acc = 3;
		const row = this.row;
		if ( (row instanceof MDataRow) && row.readOnlyDummy ) {
			acc = 2; 
		}
		const ctt = this.ctt;
		if ( (ctt instanceof CellCtt) && Validator.isPositiveInteger(ctt.acc, true) ) {
			acc = ctt.acc;
		}
		return acc;
	}

	/**
	 * @returns {Boolean} true if this cell is read/only; false otherwise
	 */
	get isReadOnly() {
		const cellAccessMode = this.cellAccessMode;
		return cellAccessMode < 3;
	}

	/**
	 * @returns {Boolean} true if this cell can be edited, i.e. it is writable; false otherwise
	 */
	get canBeEdited() {
		const cellAccessMode = this.cellAccessMode;
		return cellAccessMode >= 3;
	}

	/**
	 * @returns {Boolean} true if this cell cannot be entered; false otherwise
	 */
	get shouldBeSkipped() {
		const column = this.column;
		if ( !(column instanceof XtwCol) || !column.available || !column.visible ) {
			return true;
		}
		const cellAccessMode = this.cellAccessMode;
		return cellAccessMode < 2;
	}

	/**
	 * @returns {Boolean} true if this cell can be entered; false otherwise
	 */
	get canBeEntered() {
		return !this.shouldBeSkipped;
	}

	/**
	 * @returns {Boolean} true if this cell can be edited, i.e. it is writable; false otherwise
	 */
	get isWritable() {
		return this.cellAccessMode >= 3;
	}

	/**
	 * @returns {Boolean} true if this cell can be edited and must have a non-empty content; false otherwise
	 */
	get isMandatory() {
		return this.cellAccessMode === 4;
	}


	/**
	 * sends a notification to the web server through the row item (XRowItem)
	 * corresponding/hosting this item, if the row item (XRowItem) exists, is
	 * valid and is able to send notifications
	 * @param {String} notificationCode notification code
	 * @param {Object} parameters notification parameters
	 * @param {Boolean} blockScreenRequest flag whether to force a block screen request
	 * @return {Boolean} the success of the operation; true if the operation was successful and "_nfySrv" could be invoked, false otherwise
	 * @see XtwBody.js~XRowItem~_nfySrv
	 */
	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		const row = this.row;
		if ( !Validator.isString( notificationCode ) || !(row instanceof XRowItem) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		Object.assign( parameters, { idc: this.idc } );
		return row._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

	/**
	 * reacts to "click" mouse events
	 * @param {MouseEvent} evt the "click" mouse event
	 * @param {Object} parameters handling parameters, in case this was invoked
	 * by another method instead of by a DOM listener
	 */
	onClick( evt, parameters = void 0 ) {}

	/**
	 * reacts to "click" mouse events if this cell if of type "link"
	 * @param {MouseEvent} evt the "click" mouse event
	 */
	onLinkClick( evt ) {
		if ( evt instanceof MouseEvent ) {
			DomEventHelper.stopEvent(evt);
		}
		if ( this.alive ) {
			const body = this.xtwBody;
			if ( body instanceof XtwBody ) {
				body.onHyperlink(this.idc, this.idr);
				return true;
			}
		}
		return false;
	}

	onTooltipTimer() {
		const parameters = this.getCoordinateParameters(
			this.lastTooltipEvent, this.element );
		if ( [ this.checkbox, this.inputField, this.textarea ].every( parameter =>
				!( parameter instanceof EditableElement ) ) ) {
			this._nfySrv( "tooltipRequest", parameters );
		}
		this.clearTooltipTimer();
	}

	addContextMenuListener() {
		return this.addListener( "contextmenu", "onContextMenu" );
	}

	removeContextMenuListener() {
		return this.removeListener( "contextmenu" );
	}

	onContextMenu( evt ) {
		if ( Validator.isObject( evt ) && Validator.isString( evt.inputId ) ) {
			return;
		}
		this.selectAndFocusRow( evt );
		if ( evt instanceof MouseEvent ) {
			evt.stopPropagation();
			evt.preventDefault();
		}
		const row = this.row;
		if ( (row instanceof XRowItem) && row.alive ) {
			const parameters = this.getCoordinateParameters(evt, this.element );
			parameters.idr = row.idr;
			parameters.idc = this.idc;
			row.tblBody.onCellContextMenu(parameters);
		}
	}

	/**
	 * selects and focuses the row of this cell
	 * @param {Event} evt the DOM event
	 */
	selectAndFocusRow( evt ) {
		if ( this.row instanceof XRowItem ) {
			this.row.selectAndFocusRow( evt );
		}
	}

	/**
	 * updates the style according to the current focused status
	 * @param {Number} fcc ID currently of focused column
	 * @param {Boolean} focus row focus flag
	 */
	setCellFocusStyle(fcc, focus) {
		if ( !this.select ) {
			const fe = this.focusElement;
			if ( fe instanceof  HTMLElement ) {
				if ( focus && (this.idc === fcc) ) {
					fe.classList.add('xtwcell-focused');
				} else {
					fe.classList.remove('xtwcell-focused');
				}
			}
		}
	}

	get selectionArrow() {
		const span = window.document.createElement( "span" );
		span.style.marginLeft = "auto";
		span.style.marginRight = "auto";
		span.style.fontSize = "16px";
		span.style.color = "#717171";
		const italicIconPlaceholder = window.document.createElement( "i" );
		italicIconPlaceholder.classList.add( "fp", "fp-arrow-right" );
		span.appendChild( italicIconPlaceholder );
		return span;
	}

	addSelectionArrow() {
		if ( !this.isRendered ) {
			return false;
		}
		this.removeSelectionArrow();
		const element = this.element;
		element.style.alignItems = "center";
		const selectionArrow = this.selectionArrow;
		element.appendChild( selectionArrow );
		return true;
	}

	removeSelectionArrow() {
		if ( !this.isRendered ) {
			return false;
		}
		const element = this.element;
		element.style.alignItems = "";
		element.style.removeProperty( "alignItems" );
		const children = element.getElementsByTagName( "*" );
		if ( Validator.isIterable( children ) ) {
			for ( let child of children ) {
				if ( child === this.heightAdjustmentDiv ) {
					continue;
				}
				child.remove();
			}
		}
		element.innerHTML = "";
		if ( Validator.isFunction( this.reappendHeightAdjustmentDiv ) ) {
			this.reappendHeightAdjustmentDiv();
		}
		return true;
	}

	/**
	 * initializes a cell of a "select" column
	 * @param {HTMLElement} element the DOM element
	 */
	_iniSelectCll( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return;
		}
		element.classList.add( 'xtwcellselect' );
		if ( !(this.column instanceof XtwCol)  ) {
			element.style.setProperty( OWN_BACKGROUND_COLOR, null );
			return;
		}
		let color = XtwUtils.colorArrayToRgba( this.column.cellbgc );
		if ( !Validator.isString( color ) ) {
			color = null;
		}
		element.style.setProperty( OWN_BACKGROUND_COLOR, color );
		// TODO: element.style.backgroundColor = color;
	}

	_initDataCell( elm ) {
		this.setDatasetProperties( elm );
		// const im = pisasales.getItmMgr();
		const im = this._im;
		const col = this.column;
		// flex row --> center children vertically
		// elm.style.alignItems = 'center';
		// create focus marker element
		const fce = document.createElement('div');
		fce.className = 'xtwcell-focuselement';
		this._focusElm = fce;
		// we need another DIV to control the horizontal alignment
		const ale = document.createElement( 'div' );
		ale.className = 'xtwcell-alignelement';
		// ale.style.width = 'inherit';
		// ale.style.display = 'flex';
		// ale.style.flexDirection = 'row';
		// ale.style.boxSizing = 'border-box';
		this.setHorizontalAlignment( ale );
		this.cttElm = this.newContentSpan;
		ale.appendChild( this.cttElm );
		this._alignElm = ale;
		// attach DOM elements
		fce.appendChild(ale);
		elm.appendChild(fce);
		// set cell's default font
		this.cellFont = col.getDataFont();
		im.setFnt( elm, this.cellFont );
		this.addEditingListeners();
		this.addKeyListeners();
		if ( this.isBlobDataType ) {
			this.addDoubleClickListener();
		}
		// we add a special markers to the elements
		// elm.__psanfo = marker;
		// ald.__psanfo = marker;
		// cte.__psanfo = marker;
	}

	get newContentSpan() {
		const span = document.createElement( 'span' );
		this.addListener( "click", "onCellContentClick", span );
		let single = true;
		const cbm = this.column.contentBreakMode;
		if ( cbm && Validator.isString(cbm.mode) && Validator.isString(cbm.whiteSpace) ) {
			// set as specified
			span.style.whiteSpace = cbm.whiteSpace;
			span.style.wordBreak = 'normal';
			single = !cbm.canBreak;
		}
		if ( single ) {
			// not specified or single line forced --> single line
			this._im.setTxtOvrFlw( span, true );
		}
		return span;
	}

	onCellContentClick( evt ) {
		if ( this.link ) {
			return this.onLinkClick( evt );
		}
		return false;
	}

	removeCellContentListener() {
		if ( this.cttElm instanceof HTMLElement ) {
			this.removeListener( "click", this.cttElm );
		}
	}

	/**
	 * indicates whether this is a cell that's part of the "select" column
	 * @returns {Boolean} true if this cell is part of the "select" column; false: regular data cell
	 */
	isSelect() {
		return this.select;
	}

	/**
	 * @returns {Number} the current width of this cell in pixels
	 */
	getWidth() {
		return this.cellwdt;
	}

	/**
	 * sets a new cell width
	 * @param {Number} width new width in pixels
	 */
	setWidth( width ) {
		// const im = pisasales.getItmMgr();
		const im = this._im;
		const elm = this.getDomElement();
		im.setFlexWdt( elm, width, true );
		XtwUtils.syncZeroWidthClass( elm, width );
		this.cellwdt = width;
	}

	get clientRect() {
		if ( !this.isRendered ) {
			return void 0;
		}
		return this.element.getBoundingClientRect();
	}

	get clientHeight() {
		const clientRect = this.clientRect;
		if ( !( clientRect instanceof DOMRect ) ) {
			return void 0;
		}
		const height = clientRect.height;
		return Validator.isValidNumber( height ) ? height : void 0;
	}

	setDatasetProperties( element ) {
		if ( !( element instanceof HTMLElement ) ||
			!( element.dataset instanceof DOMStringMap ) ||
			!(this.column instanceof XtwCol)  ) {
			return false;
		}
		if ( Validator.isString( this.column.dsc ) ) {
			element.dataset.descriptionName = this.column.dsc;
		}
		if ( Validator.isString( this.column.ttip ) ) {
			element.dataset.tooltip = this.column.ttip;
		}
		if ( Validator.isObject( this.column.ctt ) && Validator.isString( this.column.ctt.text ) ) {
			element.dataset.columnText = this.column.ctt.text;
		}
		return true;
	}

	get horizontalAlignment() {
		return (this.column instanceof XtwCol) && Validator.isString( this.column.dataAlign ) ? this.column.dataAlign : void 0;
	}

	setHorizontalAlignment( element ) {
		return HtmHelper.justifyContentBasedOnTextAlignment( element, this.horizontalAlignment );
	}

	/**
	 * retrieves the original content
	 * @returns {String} the original content
	 */
	getOrigCtt() {
		if ( this.alive ) {
			const row = this.row;
			if ( row instanceof XRowItem && row.alive ) {
				const mi = row.item;
				if ( (mi instanceof MRowItem) && mi.alive && mi.isDataRow() ) {
					const mc = mi.getCell(this.idc);
					if ( mc instanceof MCell ) {
						const ctt = mc.ctt;
						if ( ctt instanceof CellCtt ) {
							return ctt.plainText;
						}
					}
				}
			}
		}
		return '';
	}

	/**
	 * sets new cell content
	 * @param {*} ctt new cell content
	 */
	setCtt( ctt ) {
		this.ctt = CellCtt.newInstance(ctt);
		this.renderContent();
		this.setColorCssVariables();
		this.scaleBlobs();
	}

	/**
	 * sets new content text, usually while editing
	 * @param {String} newContentText new content text
	 * @returns {Boolean} true if successful; false otherwise
	 */
	setContentText( newContentText ) {
		if ( typeof newContentText !== "string" || !Validator.isObject( this.ctt ) ) {
			return false;
		}
		this.ctt.text = newContentText;
		this.ctt.rawText = newContentText;
		this.setCtt( this.ctt );
		return true;
	}

	/**
	 * called if a new model item is assigned to the UI row
	 * @param {Number} idr row ID
	 * @param {Boolean} clear flag whether to clear the content
	 */
	onNewModelItem(idr, clear) {
		if ( this._idr !== idr ) {
			this._idr = idr;
			if ( clear ) {
				this.setCtt(CellCtt.EMPTY_CONTENT);
			}
		}
	}

	scaleBlobs() {
		if ( !(this.column instanceof XtwCol) || !this.column.isBlob ) {
			return false;
		}
		const imageTagsScaled = this.setImageTagSize();
		const iconSymbolsScaled = this.setIconSymbolFontSize();
		return imageTagsScaled || iconSymbolsScaled;
	}

	get referenceWidth() {
		return (this.column instanceof XtwCol) && Validator.isPositiveInteger( this.column.width, false ) ? this.column.width : void 0;
	}

	get referenceHeight() {
		const height = this.clientHeight;
		const tagHeight = (this.column instanceof XtwCol) && this.column.blobHeightDefined ? this.column.tagHeight : void 0;
		let size;
		if ( Validator.isPositiveNumber( height, false ) ) {
			size = height;
		}
		if ( Validator.isPositiveNumber( tagHeight, false ) ) {
			size = !Validator.isPositiveNumber( size, false ) ? tagHeight : Math.min( size, tagHeight );
		}
		return size;
	}

	get referenceSize() {
		const width = this.referenceWidth;
		const height = this.referenceHeight;
		let size;
		if ( Validator.isPositiveNumber( width, false ) ) {
			size = width;
		}
		if ( Validator.isPositiveNumber( height, false ) ) {
			size = !Validator.isPositiveNumber( size, false ) ? height :
				Math.min( size, height );
		}
		return size;
	}

	setImageTagSize() {
		if ( !(this.column instanceof XtwCol)  || !this.column.isBlob ) {
			return false;
		}
		if ( !( this.cttElm instanceof HTMLElement ) || !Validator.is( this.ctt, "CellCtt" ) || !this.ctt.html ) {
			return false;
		}
		const allChildren = HtmHelper.getAllLevelChildren( this.cttElm );
		if ( allChildren.length <= 0 || !allChildren.every( child => child.tagName === "IMG" ) ) {
			return false;
		}
		const width = this.referenceWidth;
		const height = this.referenceHeight;
		allChildren.forEach( image => {
			[ "width", "height" ].forEach( measurement => {
				image.removeAttribute( measurement );
			} );
			[ "width", "height", "minWidth", "min-width", "minHeight", "min-height" ]
			.forEach( measurement => {
				HtmHelper.removeStyleProperty( image, measurement );
			} );
		} );
		let atLeastOneBoundarySet = false;
		if ( Validator.isValidNumber( width ) ) {
			const maxWidth = `${ width / allChildren.length }px`;
			allChildren.forEach( image => {
				this._setSvgSize(image, false);
				image.style.maxWidth = maxWidth;
			});
			atLeastOneBoundarySet = true;
		}
		if ( Validator.isValidNumber( height ) ) {
			const maxHeight = `${ height }px`;
			allChildren.forEach( image => {
				this._setSvgSize(image, true);
				image.style.maxHeight = maxHeight;
			});
			atLeastOneBoundarySet = true;
		}
		return atLeastOneBoundarySet;
	}

	setIconSymbolFontSize() {
		if ( !(this.column instanceof XtwCol) || !this.column.isBlob ) {
			return false;
		}
		if ( !( this.cttElm instanceof HTMLElement ) ||
			!Validator.is( this.ctt, "CellCtt" ) || !this.ctt.html ) {
			return false;
		}
		const allChildren = HtmHelper.getAllLevelChildren( this.cttElm );
		if ( allChildren.length <= 0 ||
			!allChildren.every( child =>
				child.tagName === "I" && !this.isCheckboxItalicTag( child ) ) ) {
			return false;
		}
		const size = this.referenceSize;
		if ( !Validator.isValidNumber( size ) ) {
			return false;
		}
		const iconFontSize = HtmHelper.getIconFontSize( size );
		const fontSize = `${ iconFontSize }px`;
		allChildren.forEach( italicTag => italicTag.style.fontSize = fontSize );
		return true;
	}

	isCheckboxItalicTag( element ) {
		if ( !( element instanceof HTMLElement ) || element.tagName !== "I" ) {
			return false;
		}
		if ( !element.classList.contains( "fp" ) ) {
			return false;
		}
		return [ "fp-check-1", "fp-check-2" ].some( className =>
			element.classList.contains( className ) );
	}

	renderContent() {
		if ( this.checkbox instanceof Checkbox ) {
			const must_tristate = this.isWritable && !this.isMandatory;
			if ( must_tristate !== this.checkbox.isTripleState ) {
				this.checkbox.destroySelf();
				if ( must_tristate ) {
					new TripleStateCheckbox( this );
				} else {
					new Checkbox(this);
				}
			}
			return this.checkbox.render();
		}
		if ( !( this.cttElm instanceof HTMLElement ) ) {
			return false;
		}
		this.cttElm.innerHTML = '';
		const ctt = this.ctt || EMPTY_CTT;
		const innerHtml = Validator.isString( ctt.text ) ? ctt.text : '';
		if ( ctt.html ) {
			this.cttElm.innerHTML = innerHtml;
		} else {
			this.cttElm.innerText = innerHtml;
		}
		const font = Validator.isObject( ctt.prop ) ? ctt.prop.font : null;
		this._im.setFnt( this.cttElm, font );
		return true;
	}

	setColorCssVariables() {
		if ( !this.isRendered ) {
			return false;
		}
		const properties = !Validator.isObject( this.ctt ) ? {} : !Validator.is( this.ctt.prop, "CellProp" ) ? {} : this.ctt.prop;
		const mainBackgroundColor = Validator.isArray( properties.bgc, 4 ) ? Color.fromRgba( properties.bgc ) : null;
		const style = this.element.style;
		let own_bgc = null;
		let use_bgc = false;
		if ( (mainBackgroundColor instanceof Color) && (this.column instanceof XtwCol)  && mainBackgroundColor.equals( this.column.mainBackgroundColor ) ) {
			style.setProperty(ITEM_SPECIFIC_BACKGROUND_COLOR, mainBackgroundColor.stringify() );
			const alternativeBackgroundColor = this.column.alternativeBackgroundColor instanceof Color ? this.column.alternativeBackgroundColor : mainBackgroundColor;
			style.setProperty(ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR, alternativeBackgroundColor.stringify() );
			own_bgc = mainBackgroundColor;
			use_bgc = !!this.column.hasOwnBgc;
		} else {
			const rgbaBackgroundColor = XtwUtils.colorArrayToRgba( properties.bgc );
			style.setProperty( ITEM_SPECIFIC_BACKGROUND_COLOR, rgbaBackgroundColor );
			style.setProperty( ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR, rgbaBackgroundColor );
			if ( Validator.isArray(properties.bgc, 4) ) {
				own_bgc = Color.fromRgba(properties.bgc);
				use_bgc = true;
			}
		}
		let has_sel_bgc = false;
		if ( (own_bgc instanceof Color) && use_bgc && !Color.isWhite(own_bgc) && !Color.isBlack(own_bgc) ) {
			const rgba = own_bgc.toArray();
			rgba[3] = 105;		// set alpha to ~41%
			const sel_bgc = Color.fromRgba(rgba);
			if ( sel_bgc instanceof Color ) {
				style.setProperty(ITEM_SPECIFIC_SELECT_BGC, sel_bgc.stringify());
				has_sel_bgc = true;
			}
		}
		if ( !has_sel_bgc ) {
			style.removeProperty(ITEM_SPECIFIC_SELECT_BGC);
		}
		const rgbaTextColor = XtwUtils.colorArrayToRgba( properties.txc );
		style.setProperty( ITEM_SPECIFIC_TEXT_COLOR, rgbaTextColor );
		return true;
	}

	/**
	 * sets the right border if required
	 * @param {pisasales.ItmMgr} im item manager instance
	 * @param {HTMLElement} elm the HTML DOM element
	 * @param {Object} brc border color
	 */
	_setBorder( im, elm, brc ) {
		if ( brc && this.column.isVisible() ) {
			elm.style.borderRight = '1px solid ' + im.getRgb( brc );
		} else {
			elm.style.borderRight = '';
		}
	}

	/**
	 * sets a size property of a SVG image
	 * @param {HTMLImageElement} image the image element
	 * @param {Boolean} what selects the property: false -> width; true -> height
	 */
	_setSvgSize(image, what) {
		if ( 'svg' === image.getAttribute('_psa_type') ) {
			const name = what ? '_psa_height' : '_psa_width';
			const attr = image.getAttribute(name);
			if ( Validator.isString(attr) ) {
				const value = Number.parseInt(attr);
				if ( Validator.isPositiveNumber(value) ) {
					if ( what ) {
						image.style.height = '' + value + 'px';
					} else {
						image.style.width = '' + value + 'px';
					}
				}
			}
		}
	}

}
