import DomEventHelper from '../../../../utils/DomEventHelper';
import EventListenerManager from '../../../../utils/EventListenerManager';
import InputField from './InputField';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import XtwUtils from '../../util/XtwUtils';
import Utils from '../../../../utils/Utils';
import RapWidget from '../../../../utils/RapWidget';
import EditRequest from './EditRequest';
import XCellItem from '../../parts/XCellItem';

const INPUT_DROPDOWN_EVENT = "dropdown";
const INPUT_DROPDOWN_EVENT_CLOSE = "dropdownClose";
const DROPDOWN_ARROW_WIDTH = 14;
export default class DropdownInputField extends InputField {

	constructor( cellObject ) {
		super( cellObject, 'widgets.xtw.editing.DropDown' );
		const column = cellObject.column || {};
		const align = column.dataAlign || '';
		const right = 'right' !== align;	// the dropdown is an the right edge if the content is *not* right aligned!
		this.dropdownClosedByArrow = false;
		this._dropdownOpen = false;
		// store positioning flag
		this._rightEdge = !!right;
		// current dropdown widget
		this._dropDownWidget = null;
		// bind onRender event handler
		this.onRapRender = Utils.bind(this, this._onRapRender);
	}

	/**
	 * @override
	 */
	getType() {
		return 'dropdown';
	}


	get isOnRightEdge() {
		return this._rightEdge;
	}

	get dropdownOpen() {
		return !!this._dropdownOpen;
	}

	set dropdownOpen( newValue ) {
		this._dropdownOpen = !!newValue;
	}

	/**
	 * @returns {RapWidget} the current dropdown widget
	 */
	get dropDownWidget() {
		return this._dropDownWidget;
	}

	get isDummyRow() {
		const row = this.row;
		if ( !Validator.isObjectPath( row, "row.item" ) ) {
			return false;
		}
		return !!row.item._dummy;
	}

	get newArrow() {
		const arrowDiv = window.document.createElement( "div" );
		arrowDiv.classList.add( "rtp-dropdown-arrow" );
		arrowDiv.classList.add( this.isOnRightEdge ? 'rtp-dropdown-arrow-right' : 'rtp-dropdown-arrow-left');
		const arrowIcon = window.document.createElement( "i" );
		arrowIcon.classList.add( "far", "fa-angle-down" );
		arrowDiv.appendChild( arrowIcon );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onDropdownArrowClick",
			callBackPrefix: "DropdownArrow",
			element: arrowDiv,
			useCapture: false
		} );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "mousedown",
			functionName: "onDropdownArrowMousedown",
			callBackPrefix: "DropdownArrow",
			element: arrowDiv,
			useCapture: false
		} );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseup",
			functionName: "onDropdownArrowMouseup",
			callBackPrefix: "DropdownArrow",
			element: arrowDiv,
			useCapture: false
		} );
		return arrowDiv;
	}

	render() {
		super.render();
		this.dropdownArrow = this.newArrow;
		this.container.appendChild( this.dropdownArrow );
		if ( this.input && !this.isOnRightEdge ) {
			// this.input.style.left = `${DROPDOWN_ARROW_WIDTH}px`;
		}
		return true;
	}

	destroySelf() {
		this._dropDownWidget = null;
		super.destroySelf();
	}

	discardUi() {
		const arrowDiscarded = this.discardArrow();
		return super.discardUi() && arrowDiscarded;
	}

	discardArrow() {
		if ( this.dropdownArrow instanceof HTMLElement ) {
			[ "mousedown", "mouseup", "click" ].forEach( eventName => {
				EventListenerManager.removeListener( this, eventName, this.dropdownArrow, "DropdownArrow" );
			} );
		}
		return this._discardElementProperty( "dropdownArrow" );
	}

	onDropdownArrowClick( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		return this.dropdownOpen ? this.closeDropdown( domEvent ) : this.openDropdown( domEvent );
	}

	onInputBlur( domEvent ) {
		if ( this.isRelatedTargetCancelButton( domEvent ) ) {
			return this.destroySelfAndRestoreCell();
		}
		if ( this.dropdownOpen ) {
			return false;
		}
		return super.onInputBlur( domEvent );
	}

	onInputEnter( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		return super.onInputEnter( domEvent );
	}

	onInputTab( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		return super.onInputTab( domEvent );
	}

	onInputEscape( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		return super.onInputEscape( domEvent );
	}

	onVerticalArrowKeyDown( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		if ( DomEventHelper.keyIs( domEvent, "ArrowDown" ) && (domEvent.altKey || domEvent.metaKey) ) {
			domEvent.stopImmediatePropagation();
			return this.openDropdown( domEvent );
		}
		return super.onVerticalArrowKeyDown( domEvent );
	}

	isRelatedTargetCancelButton( domEvent ) {
		if ( !( domEvent instanceof Event ) || !( domEvent.relatedTarget instanceof HTMLDivElement ) ) {
			return false;
		}
		const iconContainerChildren = HtmHelper.getAllLevelChildren( domEvent.relatedTarget ).filter( element =>
				(element instanceof HTMLDivElement) && element.classList.contains( "icoDscDiv" ) );
		if ( !Validator.isArray( iconContainerChildren, 1 ) ) {
			return false;
		}
		const icons = HtmHelper.getAllLevelChildren( iconContainerChildren[ 0 ] );
		if ( !Validator.isArray( icons, 1 ) ) {
			return false;
		}
		return icons[ 0 ].tagName == "I" && [ "fa", "fa-close" ].every( className => icons[ 0 ].classList.contains( className ) );
	}

	onDropdownArrowMousedown( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	onDropdownArrowMouseup( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	pointArrowUp( arrowElement ) {
		return this.setArrowAngle( arrowElement, true );
	}

	pointArrowDown( arrowElement ) {
		return this.setArrowAngle( arrowElement, false );
	}

	setArrowAngle( arrowElement, up = true ) {
		let arrowIconItalicElement = arrowElement;
		if ( arrowIconItalicElement instanceof HTMLDivElement ) {
			const elementChildren = HtmHelper.getAllLevelChildren( arrowIconItalicElement )
				.filter( child => HtmHelper.isTagName( child, "i" ) &&
					child.classList.contains( "fa" ) );
			if ( !Validator.isArray( elementChildren, true ) ) {
				return false;
			}
			arrowIconItalicElement = elementChildren[ 0 ];
		}
		if ( !( arrowIconItalicElement instanceof HTMLElement ) ||
			!HtmHelper.isTagName( arrowIconItalicElement, "i" ) ||
			!arrowIconItalicElement.classList.contains( "fa" ) ) {
			return false;
		}
		arrowIconItalicElement.classList.remove( "fa-angle-up", "fa-angle-down" );
		arrowIconItalicElement.classList.add( ( !!up ? "fa-angle-up" : "fa-angle-down" ) );
		return true;
	}

	_onRapRender() {
		rap.off('render', this.onRapRender);
		this._focusDropDown();
	}

	_focusDropDown() {
		if ( this.alive ) {
			const dw = this.dropDownWidget;
			if ( dw instanceof RapWidget ) {
				const de = dw.element;
				if ( de instanceof HTMLElement ) {
					de.focus();
				}
			}
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	triggerDropdown() {
		this.dropdownOpen = true;
		this.openDropdown(null);
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} open 
	 * @param {String} wdgId 
	 */
	setDropdownOpenState( open, wdgId ) {
		if ( open ) {
			const rw = Utils.getRapWidget(wdgId);
			if ( rw instanceof RapWidget ) {
				this._dropDownWidget = rw;
				rap.on('render', this.onRapRender);
			}
		} else {
			this._dropDownWidget = null;
		}
		this.cell.droppedDown = open;
		if ( !this.locked && !open && !this.dropdownClosedByArrow && (window.document.activeElement !== this.input) ) {
			this.dropdownOpen = false;
			return this.onInputBlur(null);
		}
		this.dropdownClosedByArrow = false;
		this.dropdownOpen = !!open;
		this.setFocus();
		this.syncContainerWithOpenState();
		this.syncArrowDirectionWithOpenState();
	}

	syncContainerWithOpenState() {
		if ( !( this.container instanceof HTMLElement ) ) {
			return false;
		}
		this.dropdownOpen ? this.container.classList.add( "rtp-dropdown-open" ) : this.container.classList.remove( "rtp-dropdown-open" );
		return true;
	}

	syncArrowDirectionWithOpenState() {
		this.dropdownOpen ? this.pointArrowUp( this.dropdownArrow ) : this.pointArrowDown( this.dropdownArrow );
	}

	closeDropdown( domEvent ) {
		this.dropdownClosedByArrow = true;
		// this.dropdownOpen = false;
		// this.pointArrowDown( this.dropdownArrow );
		this.input.focus();
		return this.informAboutDropdownClose();
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {KeyboardEvent | MouseEvent | null} domEvent the DOM event; may be null
	 */
	openDropdown( domEvent ) {
		if ( this.isDummyRow && !this.insertionDummy ) {
			return false;
		}
		this.dropdownClosedByArrow = false;
		const cellElement = (this.cell instanceof XCellItem) ? this.cell.element : null;
		const parameters = XtwUtils.getCoordinateParameters( domEvent, cellElement );
		this.cell.droppedDown = true;
		return this.informAboutDropdown( parameters );
	}

	informAboutDropdown( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput(INPUT_DROPDOWN_EVENT, parameters, !!blockScreenRequest );
	}

	informAboutDropdownClose( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput(INPUT_DROPDOWN_EVENT_CLOSE, parameters, !!blockScreenRequest );
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} horz 
	 * @param {Boolean} up 
     * @param {KeyboardEvent} ke
	 */
	needsArrowKey(horz, up, ke) {
		if ( this.dropdownOpen ) {
			return true;
		}
		return super.needsArrowKey(horz, up, ke);
	}

	/**
	 * @override
	 */
	setFocus() {
		if ( this.alive ) {
			if ( this.dropdownOpen ) {
				this._focusDropDown();
			} else {
				super.setFocus();
			}
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	createEditRequest() {
		const er = super.createEditRequest();
		if ( (er instanceof EditRequest) && this.dropdownOpen ) {
			er.dropdown = true;
		}
		return er;
	}
}
