import LoggingBase from "../../../../base/loggingbase";
import Validator from "../../../../utils/Validator";

export default class EditRequest extends LoggingBase {

    /**
     * constructs a new instance
     * @param {Number} cid column ID of the new edit target
     * @param {Number} rid row ID of the new edit target
     * @param {Boolean} mode insert mode flag
     * @param {Boolean} keep "keep forced" flag
     */
    constructor(cid, rid, mode, keep) {
        super('widgets.xtw.editing.EditRequest');
        this._cid = cid;
        this._rid = rid;
        this._mode = !!mode;
        this._keep = !!keep;
        this._dropdown = false;
    }

    /**
     * @override
     */
    doDestroy() {
        delete this._cid;
        delete this._rid;
        super.doDestroy();
    }

    /**
     * @returns {Number} the column ID of the new edit target
     */
    get columnId() {
        return this._cid;
    }

    /**
     * @returns {Number} the row ID of the new edit target
     */
    get rowId() {
        return this._rid;
    }

	/**
	 * @returns {Boolean} true if the current edit mode is an "insert" mode; false otherwise
	 */
    get insertMode() {
        return this._mode;
    }

    /**
     * @returns {Boolean} true if this instance should be kept on any data operation; false for common behavior
     */
    get isKeepForced() {
        return this._keep;
    }

    /**
     * @returns {Boolean} true if a new cell editor should enter "dropped down" state immediately
     */
    get dropdown() {
        return this._dropdown;
    }

    /**
     * @param {Boolean} drp "dropped down" state
     */
    set dropdown(drp) {
        this._dropdown = !!drp;
    }

    /**
     * creates a new instance
     * @param {Number} cid column ID of the new edit target
     * @param {Number} rid row ID of the new edit target
     * @param {Boolean} mode insert mode flag
     * @param {Boolean} keep "keep forced" flag
     * @returns {EditRequest | null} the created instance or null if parameters are invalid
     */
    static createInstance(cid, rid, mode, keep) {
        if ( !Validator.isStringOrNumber(cid) || !Validator.isStringOrNumber(rid) ) {
            return null;
        }
        if ( (Validator.isNumber(cid) && (cid <= 0)) || (Validator.isNumber(rid) && (rid <= 0)) ) {
            return null;
        }
        return new EditRequest(cid, rid, mode, keep);
    }
}