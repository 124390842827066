import DomEventHelper from "../../../../utils/DomEventHelper";
import Validator from "../../../../utils/Validator";
import XCellItem from "../../parts/XCellItem";
import CharactersCounterExtension from "./CharactersCounterExtension";
import EditableElement from "./EditableElement";

export default class BaseInput extends EditableElement {

	/**
	 * constructs a new instance
	 * @param {XCellItem} cellObject the target cell
	 * @param {String} ln logger name
	 */
    constructor(cellObject, ln) {
        super(cellObject, ln || 'widgets.xtw.editing.BaseInput');
        this._cce = new CharactersCounterExtension(this);
    }

	/**
	 * @inheritdoc
	 * @override
	 */
	isEditCtrl() {
		return true;
	}

    /**
     * @returns {CharactersCounterExtension} the "character count" extension
     */
    get cce() {
        return this._cce;
    }

	/**
	 * @inheritdoc
	 * @override
	 */
    destroySelf() {
        if ( this.alive ) {
            try {
				this._cce.destroy();
				delete this._cce;
            } finally {
                super.destroySelf();
            }
        }
    }

    /**
     * handles "clipboard paste" events
     * @param {Event} domEvent the "clipboard paste" event
     * @returns {Boolean} true if successfully handled; false otherwise
     */
    onPasteEvent(domEvent) {
		const current = this.inputValue;
		const pastedContent = DomEventHelper.getClipboardDataTransferText( domEvent );
		if ( !Validator.isString( pastedContent ) ) {
			return false;
		}
		const can_edit = this.canBeEdited;
		const edit_allowed = this.editingAllowed;
		const available = this.cce.allowedInsertionLength;
		if ( !can_edit || !edit_allowed ) {
			if ( domEvent instanceof Event ) {
				domEvent.stopImmediatePropagation();
			}
			DomEventHelper.stopEvent(domEvent);
		} else {
			if ( this.isTraceEnabled() ) {
				this.trace(`Got pasted clipboard data "${pastedContent}". Trigger processing...`);
			}
			if ( available < pastedContent.length ) {
				const param = {
					current: current,
					clpData: pastedContent,
					available: available,
					capacity: this.cce.maxCharacterCount,
					selected: this.cce.selectedContentLength
				};
				this.informAboutInput('clipboardLimit', param, false);
			}
			const self = this;
			const instanceID = self.instanceID;
			setTimeout(() => {
				if ( self.alive ) {
					self._handleInputPaste(0, current, pastedContent);
				} else {
					console.warn(`Instance "${instanceID}" not longer alive!`);
				}
			}, 0);
		}
		return true;
    }

	/**
	 * called if the input text was truncated due to capacity limit was exceeded
	 */
	onTruncated() {
		// abstract
	}

	/**
	 * processes "clipboard paste" events
	 * @param {Number} depth recursion depth
	 * @param {String} before the content before the paste operation
	 * @param {String} pasted the pasted content
	 */
	_handleInputPaste(depth, before, pasted) {
		if ( this.alive ) {
			if ( this.isTraceEnabled() ) {
				this.trace(`Processing pasted clipboard data "${pasted}".`);
			}
			if ( this.canBeEdited && this.editingAllowed ) {
				const value = this.inputValue;
				if ( !value.includes(pasted) ) {
					// oops?!
					this.trace(`Current content "${value}" does not yet contain pasted clipboard data.`);
					if ( depth === 0 ) {
						// try one more time
						this.trace('Triggering another cycle...');
						const self = this;
						setTimeout(() => {
							if ( self.alive ) {
								self._handleInputPaste(depth + 1, before, pasted);
							}
						}, 25);
						return;
					} else {
						this.trace('Second attempt to process clipboard data may fail. Giving up.');
					}
				}
				const capacity = this.cce.maxCharacterCount;
				if ( (capacity > 0) && (capacity < value.length) ) {
					// truncate!
					this.input.value = value.substring(0, capacity);
					this.onTruncated();
				}
				if ( !this.dirty ) {
					this.dirty = true;
					this.informAboutEditing( { specialOperation: 'paste' } );
				}
				if ( this.alive ) {
					this.informAboutContentChange();
				}
			} else {
				if ( this.isTraceEnabled() ) {
					this.trace(`Editing not allowed. Resetting content to "${before}".`);
				}
				const input = this.input;
				if ( (input instanceof HTMLInputElement) || (input instanceof HTMLTextAreaElement) ) {
					input.value = Validator.ensureString(before);
				}
			}
		}
	}


}