import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';

export default class XtwColTooltipExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get defaultTooltip() {
		return Validator.isString( this.ttip ) ? this.ttip : "";
	}

	get tableEndX() {
		return Validator.isObject( this.xtdTbl ) && "tableEndX" in this.xtdTbl ?
			this.xtdTbl.tableEndX : void 0;
	}

	get spanTooltipRect() {
		if ( !( this.spanTooltip instanceof HTMLElement ) ) {
			return void 0;
		}
		return this.spanTooltip.getBoundingClientRect();
	}

	get spanTooltipWidth() {
		const spanTooltipRect = this.spanTooltipRect;
		if ( !( spanTooltipRect instanceof DOMRect ) ) {
			return void 0;
		}
		return spanTooltipRect.width;
	}

	get spanTooltipEndX() {
		const spanTooltipRect = this.spanTooltipRect;
		if ( !( spanTooltipRect instanceof DOMRect ) ) {
			return void 0;
		}
		return spanTooltipRect.x + spanTooltipRect.width;
	}

	set spanTooltipWidth( amountOfPixels ) {
		if ( !( this.spanTooltip instanceof HTMLElement ) ||
			!Validator.isPositiveNumber( amountOfPixels ) ) {
			return;
		}
		this.spanTooltip.style.width = `${ amountOfPixels }px`;
	}

	setAsActiveSumColumn() {
		const table = this.xtdTbl;
		if ( !Validator.isObject( table ) ) {
			return false;
		}
		table.activeMouseoverTooltipSumColumn = this;
		return true;
	}

	removeAsActiveSumColumn() {
		const table = this.xtdTbl;
		if ( !Validator.isObject( table ) ||
			table.activeMouseoverTooltipSumColumn != this ) {
			return false;
		}
		table.activeMouseoverTooltipSumColumn = void 0;
		delete table.activeMouseoverTooltipSumColumn;
		return true;
	}

	addTooltipListeners() {
		const mouseEnterListenerAdded = this.addListener("mouseenter", "onMouseEnter");
		const mouseLeaveListenerAdded = this.addListener("mouseleave", "onMouseLeave");
		return mouseEnterListenerAdded && mouseLeaveListenerAdded;
	}

	removeTooltipListeners() {
		this.removeListener("mouseenter");
		this.removeListener("mouseleave");
	}

	/**
	 * "mouseenter" listener
	 * @param {MouseEvent} domEvent 
	 */
	onMouseEnter( domEvent ) {
		this.onMouseLeave();
		this.removeExistingTooltips();
		const has_ttp = Validator.isString(this.defaultTooltip) || ((this.type === 1) && this.xtwHead.isColSumEnabled);
		if ( !has_ttp ) {
			// avoid showing empty tooltips
			return;
		}
		if ( this.createSpanTooltip( domEvent.clientY, domEvent.clientX ) ) {
			const self = this;
			window.setTimeout( () => {
				if ( !self.isRendered || !( self.spanTooltip instanceof HTMLElement ) ) {
					return false;
				}
				self.spanTooltip.classList.remove( "invisible" );
			}, 500 );
		}
		this.tooltipSum = void 0;
		delete this.tooltipSum;
		this.removeAsActiveSumColumn();
		if ( !this.select && (this.type === 1) ) {
			// numeric field - check for "column sum"
			this.notifyServer( "columnHover" );
		}
	}

	removeExistingTooltips() {
		const table = this.xtdTbl;
		return Validator.isFunctionPath( table, "table.removeAllColumnTooltips" ) ? table.removeAllColumnTooltips() : false;
	}

	onMouseLeave( domEvent ) {
		this.tooltipSum = void 0;
		delete this.tooltipSum;
		this.destroySpanTooltip();
		this.removeAsActiveSumColumn();
	}

	createSpanTooltip( top, left ) {
		if ( !this.isRendered ) {
			return false;
		}
		this.element.title = "";
		this.spanTooltip = this.newSpanTooltip;
		if ( Validator.isValidNumber( top ) ) {
			this.spanTooltip.style.top = top + 5 + "px";
		}
		if ( Validator.isValidNumber( left ) ) {
			this.spanTooltip.style.left = left + 5 + "px";
		}
		this.element.appendChild( this.spanTooltip );
		this.spanTooltip.classList.add( "invisible" );
		this.adjustSpanTooltipWidth();
		return true;
	}

	destroySpanTooltip() {
		if ( this.isRendered ) {
			this.element.title = this.defaultTooltip;
		}
		let element = this.spanTooltip;
		this.spanTooltip = void 0;
		delete this.spanTooltip;
		if ( !( element instanceof HTMLElement ) ) {
			return true;
		}
		element.innerHTML = "";
		element.remove();
		element = void 0;
		return true;
	}

	adjustSpanTooltipWidth() {
		const spanTooltipEndX = this.spanTooltipEndX;
		if ( !Validator.isValidNumber( spanTooltipEndX ) ) {
			return false;
		}
		const tableEndX = this.tableEndX;
		if ( !Validator.isValidNumber( tableEndX ) ) {
			return false;
		}
		if ( tableEndX >= spanTooltipEndX ) {
			return true;
		}
		const widthAdjustment = spanTooltipEndX - tableEndX;
		this.spanTooltipWidth = this.spanTooltipWidth - widthAdjustment;
		return true;
	}

	get newSpanTooltip() {
		const spanTooltip = window.document.createElement( "span" );
		spanTooltip.classList.add( "xtwheader-tooltip" );
		spanTooltip.role = "tooltip";
		spanTooltip.innerHTML = this.defaultTooltip;
		if ( this.mainSpan instanceof HTMLElement ) {
			spanTooltip.style.fontFamily = this.mainSpan.style.fontFamily;
			spanTooltip.style.fontSize = this.mainSpan.style.fontSize;
			spanTooltip.style.fontWeight = this.mainSpan.style.fontWeight;
		}
		return spanTooltip;
	}

	setTooltip( newTooltipValue ) {
		if ( !Validator.isString( newTooltipValue ) ) {
			return false;
		}
		if ( this.element instanceof HTMLElement ) {
			this.element.title = newTooltipValue;
		}
		return true;
	}

	addToSpanTooltip( addition ) {
		if ( !( this.spanTooltip instanceof HTMLElement ) ) {
			return false;
		}
		let ttp = this.defaultTooltip;
		if ( Validator.isString(addition) ) {
			if ( Validator.isString(ttp) ) {
				ttp += '<br/>';
			}
			ttp += addition;
		}
		this.spanTooltip.innerHTML = ttp;
		return true;
	}

	addSumToTooltip( sum ) {
		if ( this.type !== 1 ) { // only if the type is equal to 1, it is a numeric field
			return false;
		}
		if ( Validator.isString(sum) ) {
			this.tooltipSum = sum;
			this.setAsActiveSumColumn();
			return this.addToSpanTooltip( 'Σ = ' + sum );
		} else {
			this.tooltipSum = null;
			if ( !Validator.isString(this.defaultTooltip) ) {
				this.onMouseLeave();
			}
			return this.addToSpanTooltip('');
		}
	}

	handleCopyTooltipSumRequest() {
		if ( this.type != 1 ) { // if the type is equal to 1, it is a numeric field
			return false;
		}
		this.notifyServer( "copyTooltipSum", { tooltipSum: this.tooltipSum } );
		return true;
	}

	makeSureTableIsFocused() {
		const table = this.xtdTbl;
		if ( !Validator.isFunctionPath( table, "table.makeSureTableIsFocused" ) ) {
			return false;
		}
		return table.makeSureTableIsFocused();
	}

}
