import KeyHdl from './KeyHdl';
import Logging from '../utils/logging';

/** minimal (time) distance between two identical key strokes */
const MIN_KEY_DISTANCE = 1500;
/** the [F5] key */
const KEY_F5 = 'F5';
/** modifier key names */
const MODIFIERS = [ 'Alt', 'Control', 'Meta', 'Shift', 'AltGraph' ];

/**
 * a key stroke info class
 */
class KeyStroke {

	/**
	 * constructs a new instance
	 * @param {String} key the key
	 */
	constructor(key) {
		this.key = key;
		this.blocked = false;
		this.tms = 0;
	}

	toString() {
		return `{${ this.key }: tms=${ this.tms }, blocked=${ this.blocked }}`;
	}
}

/**
 * global key listener class
 */
export default class GlbKeyLis {
	
	/**
	 * constructs a new instance
	 */
	constructor() {
		this.logger = Logging.getInstance().getLogger('key.GlbKeyLis');
		this._strokeMap = new Map();
		this._strokeMap.set(KEY_F5, new KeyStroke(KEY_F5));
		const qxdoc = rwt.widgets.base.ClientDocument.getInstance();
		qxdoc.addEventListener("keydown", this._onQxKeyDown, this);
		// set keydown and keyup listeners in capturing phase
		const dom_doc = window.document;
		const self = this;
		dom_doc.addEventListener('keydown', (evt) => {
			return self._allowKeyEvent(evt, false);
		}, true);
		dom_doc.addEventListener('keyup', (evt) => {
			return self._allowKeyEvent(evt, true);
		}, true);
		dom_doc.addEventListener('focusin', (e) => {
			self._traceFocus(e, false);
		}, true);
		dom_doc.addEventListener('focusout', (e) => {
			self._traceFocus(e, true);
		}, true);
	}

	/**
	 * RAP key down event handler
	 * @param {Event} evt the keystroke event
	 */
	_onQxKeyDown(evt) {
		const khd = KeyHdl.getInstance();
		if ( khd ) {
			return khd.hdlKey(evt, true);
		}
		return false;
	}

	/**
	 * modifies a keyboard event
	 * @param {KeyboardEvent} ke the keyboard event
	 */
	_hookKeyEvent(ke) {
		if ( (ke instanceof KeyboardEvent) && this.logger.isTraceEnabled() ) {
			const logger = this.logger;
			const _sp_fnc = ke.stopPropagation;
			const _sip_fnc = ke.stopImmediatePropagation;
			const _pd_fnc = ke.preventDefault;

			ke.stopPropagation = () => {
				logger.trace('EVENT HOOK - Stop propagation:', ke);
				_sp_fnc.apply(ke);
			}
			ke.stopImmediatePropagation = () => {
				logger.trace('EVENT HOOK - Stop immediate propagation:', ke);
				_sip_fnc.apply(ke);
			}
			ke.preventDefault = () => {
				logger.trace('EVENT HOOK - Prevent default:', ke);
				_pd_fnc.apply(ke);
			}
		}
	}

	/**
	 * checks whether a key event is allowed or it it should be suppressed
	 * @param {KeyboardEvent} key_evt the DOM keyboard event
	 * @param {Boolean} up "key up" flag
	 * @returns {Boolean} true if the key event is allowed; false if it should be suppressed and blocked
	 */
	_allowKeyEvent(key_evt, up) {
		const key = key_evt.key || '';
		if ( this.logger.isTraceEnabled() && !MODIFIERS.some(m => m === key) ) {
			this.logger.trace(`${key_evt.type}: "${key}" target: `, key_evt.target);
		}
		this._hookKeyEvent(key_evt);
		let check = false;
		// at this moment we check only for [F5] key; we *don't* care about modifiers here!
		switch ( key ) {
			case KEY_F5:
				check = true;
				break;
			default:
				break;
		}
		let allow = true;
		if ( check ) {
			if ( this.logger.isDebugEnabled() ) {
				this.logger.debug(`Checking key stroke "${ key }" (${ (up ? 'keyup' : 'keydown') })...`);
			}
			const stroke = this._strokeMap.get(key);
			if ( stroke ) {							// should always be available, but paranoia rulez :-)
				const now = Date.now();
				if ( !up ) {
					// new "keydown" event - check timestamp
					if ( (now - stroke.tms) < MIN_KEY_DISTANCE ) {
						// block!
						allow = false;
					}
					stroke.tms = now;
				} else {
					// a "keyup" event in blocked state is never allowed, otherwise we have nothing to do here
					allow = !stroke.blocked;
				}
				// update "blocked" state
				stroke.blocked = !allow;
			}
			if ( !allow && this.logger.isDebugEnabled() ) {
				this.logger.debug(`Key stroke "${ key }" suppressed!`);
			}
		}
		if ( !allow ) {
			key_evt.stopPropagation();
			key_evt.preventDefault();
		}
		return allow;
	}

	/**
	 * traces the focus transfer
	 * @param {FocusEvent} e the focus event
	 * @param {Boolean} out false: focusin, true: focusout
	 */
	_traceFocus(e, out) {
		if ( this.logger.isTraceEnabled() ) {
			if ( out ) {
				this.logger.trace('--- FOCUS --- removed from ', e.target);
			} else {
				this.logger.trace('+++ FOCUS +++ set to', e.target);
			}
		}
	}
}	

console.debug('key/glbkeylis.js loaded.');
